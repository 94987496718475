import Tracker from '../../helpers/tracker';
import { navigate } from '../../helpers/dom-interact';
import { GA4BannerTracker } from '../GA4/GA4BannerTracker';

/**
 * Mimic Link it's used for faking a link when we can not use an "a" tag.
 *
 * How to use it:
 * Just add the class 'mimic-link' to the element which will behave as a link and
 * this element must have the URL to redirect to in the data attribute 'data-href'
 *
 * In order to track click events (to google analytics) from MimicLinks you will
 * need to add also the class 'tracking-link' and add the data attribute 'data-tracking-action',
 * the label for links is the url so that will be taken from the data-href
 * */
export default {
	bindMimicLinks(trackingCategory) {
		jQuery(document).on('click', '.mimic-link', (e) => {
			const currentTarget = jQuery(e.currentTarget);
			const isMobileMenuOpener = jQuery(window).width() < 1001 && currentTarget.hasClass('sub-menu');

			if (e.target.tagName === 'A' || isMobileMenuOpener) {
				return;
			}

			e.stopPropagation();
			e.preventDefault();

			const link = currentTarget.data('href');

			if (typeof link === 'undefined') {
				return;
			}

			const openInNewTab = currentTarget.hasClass('js-new-window');

			if (trackingCategory && currentTarget.hasClass('tracking-link')) {
				const trackingValue = currentTarget.data('tracking-value');
				const trackingAction = currentTarget.data('tracking-action');
				const trackingLabel = currentTarget.data('tracking-label') || link;

				GA4BannerTracker.trackBannerClick({
					block_section: trackingAction,
					block_index: trackingValue ? trackingValue.toString() : null,
					content_name: trackingLabel,
					content_id: trackingValue ? trackingValue.toString() : null,
				});

				Tracker.trackAndNavigate(
					{
						category: trackingCategory,
						action: trackingAction,
						label: trackingLabel,
						value: trackingValue ? parseInt(trackingValue, 10) : undefined,
					},
					link,
					openInNewTab
				);
			} else {
				navigate(link, openInNewTab);
			}
		});
	},
};
