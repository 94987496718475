"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4EcommerceTracker = void 0;
var GA4Tracker_1 = require("./GA4Tracker");
var GA4TrackerVars_1 = require("./GA4TrackerVars");
var GA4EcommerceTracker = /** @class */ (function () {
    function GA4EcommerceTracker() {
    }
    GA4EcommerceTracker.trackViewItemList = function (itemListId, itemListName, items) {
        var eventOptions = {
            event: 'Ecommerce - view_item_list',
            event_name: 'view_item_list',
            item_list_id: itemListId,
            item_list_name: itemListName,
            ecommerce: {
                items: items,
            },
            clearEcommerce: true,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4EcommerceTracker.trackViewItem = function (items) {
        var eventOptions = {
            event: 'Ecommerce - view_item',
            event_name: 'view_item',
            ecommerce: {
                items: items,
            },
            clearEcommerce: true,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions, { delay: 500 });
    };
    GA4EcommerceTracker.trackSelectItem = function (itemListId, itemListName, item) {
        var eventOptions = {
            event: 'Ecommerce - select_item',
            event_name: 'select_item',
            ecommerce: {
                item_list_id: itemListId,
                item_list_name: itemListName,
                items: [item],
            },
            clearEcommerce: true,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4EcommerceTracker.trackAddToCart = function (items) {
        var totalPrice = GA4EcommerceTracker.calcTotalPrice(items);
        var eventOptions = {
            event: 'Ecommerce - add_to_cart',
            event_name: 'add_to_cart',
            ecommerce: {
                currency: (0, GA4TrackerVars_1.getCurrency)(),
                value: totalPrice,
                items: items,
            },
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4EcommerceTracker.trackRemoveFromCart = function (items) {
        var totalPrice = GA4EcommerceTracker.calcTotalPrice(items);
        var eventOptions = {
            event: 'Ecommerce - remove_from_cart',
            event_name: 'remove_from_cart',
            ecommerce: {
                currency: (0, GA4TrackerVars_1.getCurrency)(),
                value: totalPrice,
                items: items,
            },
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4EcommerceTracker.trackViewCart = function (items) {
        var totalPrice = GA4EcommerceTracker.calcTotalPrice(items);
        var eventOptions = {
            event: 'Ecommerce - view_cart',
            event_name: 'view_cart',
            ecommerce: {
                currency: (0, GA4TrackerVars_1.getCurrency)(),
                value: totalPrice,
                items: items,
            },
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions, { delay: 500 });
    };
    GA4EcommerceTracker.trackAddToWishlist = function (items) {
        var totalPrice = GA4EcommerceTracker.calcTotalPrice(items);
        var eventOptions = {
            event: 'Ecommerce - add_to_wishlist',
            event_name: 'add_to_wishlist',
            ecommerce: {
                currency: (0, GA4TrackerVars_1.getCurrency)(),
                value: totalPrice,
                items: items,
            },
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4EcommerceTracker.trackBeginCheckout = function (items) {
        var totalPrice = GA4EcommerceTracker.calcTotalPrice(items);
        var eventOptions = {
            event: 'Ecommerce - begin_checkout',
            event_name: 'begin_checkout',
            business_private: 'private',
            ecommerce: {
                currency: (0, GA4TrackerVars_1.getCurrency)(),
                value: totalPrice,
                items: items,
            },
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions, { delay: 500 });
    };
    GA4EcommerceTracker.trackAddShippingInfo = function (items, shippingTier) {
        var totalPrice = GA4EcommerceTracker.calcTotalPrice(items);
        var eventOptions = {
            event: 'Ecommerce - add_shipping_info',
            event_name: 'add_shipping_info',
            business_private: 'private',
            shipping_tier: shippingTier,
            ecommerce: {
                currency: (0, GA4TrackerVars_1.getCurrency)(),
                value: totalPrice,
                items: items,
            },
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4EcommerceTracker.trackAddPaymentInfo = function (items, paymentType) {
        var totalPrice = GA4EcommerceTracker.calcTotalPrice(items);
        var eventOptions = {
            event: 'Ecommerce - add_payment_info',
            event_name: 'add_payment_info',
            business_private: 'private',
            payment_type: paymentType,
            ecommerce: {
                currency: (0, GA4TrackerVars_1.getCurrency)(),
                value: totalPrice,
                items: items,
            },
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4EcommerceTracker.trackPurchase = function (transactionId, total, tax, shipping, items, isSampleOrder, isRegularOrder, coupon) {
        var totalPrice = total === null ? GA4EcommerceTracker.calcTotalPrice(items) + tax : total;
        var eventOptions = {
            event: 'Ecommerce - purchase',
            event_name: 'purchase',
            sample_order: isSampleOrder ? 'YES' : 'NO',
            regular_order: isRegularOrder ? 'YES' : 'NO',
            ecommerce: {
                currency: (0, GA4TrackerVars_1.getCurrency)(),
                value: totalPrice,
                items: items,
                transaction_id: transactionId,
                tax: tax,
                shipping: shipping,
                coupon: coupon || '',
            },
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions, { delay: 500 });
    };
    GA4EcommerceTracker.calcTotalPrice = function (items) {
        return items.reduce(function (accumulator, currentValue) {
            if (currentValue.price != null && currentValue.quantity != null) {
                return accumulator + currentValue.price * currentValue.quantity;
            }
            return accumulator;
        }, 0);
    };
    return GA4EcommerceTracker;
}());
exports.GA4EcommerceTracker = GA4EcommerceTracker;
