"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4UserEventsTracker = void 0;
var GA4Tracker_1 = require("./GA4Tracker");
var GA4UserEventsTracker = /** @class */ (function () {
    function GA4UserEventsTracker() {
    }
    GA4UserEventsTracker.trackUserLogin = function (modalPosition) {
        var loginEvent = {
            event: 'custom_event',
            event_name: 'user_login',
            user_login: {
                modal_position: modalPosition,
            },
        };
        GA4Tracker_1.GA4Tracker.track(loginEvent);
    };
    GA4UserEventsTracker.trackUserForgotPassword = function (modalPosition) {
        var forgotPasswordEvent = {
            event: 'custom_event',
            event_name: 'user_login_forgot_password',
            user_login_forgot_password: {
                modal_position: modalPosition,
            },
        };
        GA4Tracker_1.GA4Tracker.track(forgotPasswordEvent);
    };
    GA4UserEventsTracker.trackUserRegistration = function (modalPosition) {
        var registrationEvent = {
            event: 'custom_event',
            event_name: 'user_registration',
            user_registration: {
                modal_position: modalPosition,
            },
        };
        GA4Tracker_1.GA4Tracker.track(registrationEvent);
    };
    return GA4UserEventsTracker;
}());
exports.GA4UserEventsTracker = GA4UserEventsTracker;
