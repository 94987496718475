export function getQueryParamsObject(queryStr) {
	if (!queryStr) {
		return {};
	}

	const parametersObject = {};
	const paramsArray = queryStr.split('&');

	for (let i = 0, len = paramsArray.length; i < len; i++) {
		const paramArray = paramsArray[i].split('=');

		if (paramArray[0] && paramArray[1]) {
			parametersObject[paramArray[0]] = decodeURIComponent(paramArray[1]);
		}
	}

	return parametersObject;
}

export function getUrlParams(whiteList, str) {
	let urlParams = typeof str !== 'undefined' ? str : window.location.search.substr(1);

	if (!urlParams.length) {
		return {};
	}

	urlParams = getQueryParamsObject(urlParams);

	if (whiteList && whiteList.length > 0) {
		urlParams = _.pick(urlParams, whiteList);
	}

	return urlParams;
}

export function setUrl(params) {
	if (typeof window.history.replaceState === 'undefined') {
		return;
	}

	if (!params) {
		window.history.replaceState('', 'reset url', window.location.pathname);
	} else {
		window.history.replaceState('', 'new url with parameters', `${window.location.pathname}?${params}`);
	}
}

export function objectToParams(obj) {
	let str = '';

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			if (str !== '') {
				str += '&';
			}
			str += `${key}=${encodeURIComponent(obj[key])}`;
		}
	}

	return str;
}
