"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadAppModalTemplateComponentTypes = exports.DownloadAppModalEvents = void 0;
var DownloadAppModalEvents;
(function (DownloadAppModalEvents) {
    DownloadAppModalEvents["POPUP_OPEN"] = "open:real:door";
    DownloadAppModalEvents["POPUP_CLOSE"] = "close:real:door";
})(DownloadAppModalEvents = exports.DownloadAppModalEvents || (exports.DownloadAppModalEvents = {}));
var DownloadAppModalTemplateComponentTypes;
(function (DownloadAppModalTemplateComponentTypes) {
    DownloadAppModalTemplateComponentTypes["CONTINUE_ON_WEB"] = "continue-on-web";
    DownloadAppModalTemplateComponentTypes["DOWNLOAD_APP"] = "download-app";
})(DownloadAppModalTemplateComponentTypes = exports.DownloadAppModalTemplateComponentTypes || (exports.DownloadAppModalTemplateComponentTypes = {}));
