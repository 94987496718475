"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4HeaderMenuTracker = void 0;
var GA4Tracker_1 = require("./GA4Tracker");
// This class now only handles tracking, not event listening
var GA4HeaderMenuTracker = /** @class */ (function () {
    function GA4HeaderMenuTracker() {
    }
    GA4HeaderMenuTracker.trackMenuInteraction = function (menuConfig) {
        GA4Tracker_1.GA4Tracker.track(menuConfig);
    };
    return GA4HeaderMenuTracker;
}());
exports.GA4HeaderMenuTracker = GA4HeaderMenuTracker;
