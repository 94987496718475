"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentryLogger = void 0;
var Sentry = __importStar(require("@sentry/browser"));
var tracing_1 = require("@sentry/tracing");
var EnvironmentLocator_1 = require("./EnvironmentLocator");
var SentryLogger = /** @class */ (function () {
    function SentryLogger() {
        this.isSentryAvailable = false;
        if (!SentryLogger.instance) {
            SentryLogger.instance = this;
        }
    }
    SentryLogger.getInstance = function () {
        return SentryLogger.instance;
    };
    SentryLogger.prototype.initializeSentry = function (environment) {
        if (environment.isProduction || environment.isStage) {
            this.isSentryAvailable = true;
            Sentry.init({
                dsn: 'https://f287e95de233461c8a7d29ab168d5f61@sentry.io/5187717',
                integrations: [new tracing_1.Integrations.BrowserTracing()],
                tracesSampleRate: environment.isProduction ? 0.0 : 1,
                environment: environment.isProduction ? EnvironmentLocator_1.EnvironmentNames.production : EnvironmentLocator_1.EnvironmentNames.stage,
                ignoreErrors: ['Attempted to assign to readonly property.'],
            });
        }
    };
    SentryLogger.prototype.sendInfoReport = function (reportSettings) {
        if (!this.isSentryAvailable) {
            return;
        }
        Sentry.withScope(function (scope) {
            if (reportSettings.extra) {
                scope.setExtra(reportSettings.extra.extraMessage, reportSettings.extra.value);
            }
            if (reportSettings.level) {
                scope.setLevel(reportSettings.level);
            }
            scope.addBreadcrumb({}, 100);
            Sentry.captureMessage(reportSettings.message);
        });
    };
    SentryLogger.instance = new SentryLogger();
    return SentryLogger;
}());
exports.SentryLogger = SentryLogger;
