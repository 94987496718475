import { cssClass } from '../../helpers/dom-interact';

export class LoadingIndicator {
	constructor() {
		this.el = document.createElement('div');
		this.el.innerHTML = '<span class="spinner"></span>';

		cssClass.add(this.el, ['js-ajax-overlay', 'ajax-overlay', 'is-hidden']);

		document.body.appendChild(this.el);
	}

	showLoader() {
		this.el.classList.remove('is-hidden');
	}

	hideLoader() {
		this.el.classList.add('is-hidden');
	}
}
