import { navigate } from './dom-interact';
import { getUrlParams } from './handle-url';
import { MasterFeedIdBuilder } from './MasterFeedIdBuilder';

/**
 *
 * @returns {boolean}
 */
function isTrackingEnabled() {
	return !(typeof window.dataLayer === 'undefined');
}

/**
 *
 * @param options
 * @returns {{event: string, category}}
 */
function prepareOptions(options) {
	if (typeof options.category === 'undefined') {
		throw new Error('Missing "category" parameter');
	}

	const trackingOptions = {
		event: 'fury_event',
		category: options.category,
	};

	if (typeof options.action !== 'undefined') {
		trackingOptions.action = options.action;

		if (typeof options.label !== 'undefined') {
			trackingOptions.label = options.label;

			if (typeof options.value !== 'undefined') {
				trackingOptions.value = options.value;
			}
		}
	}

	return trackingOptions;
}

export default {
	track(options) {
		if (!isTrackingEnabled()) {
			return false;
		}
		window.dataLayer.push(prepareOptions(options));
	},

	trackAndNavigate(options, url, openInNewWindow = false) {
		if (!isTrackingEnabled()) {
			navigate(url, openInNewWindow);

			return;
		}

		try {
			const trackingOptions = prepareOptions(options);
			const navigateTimeout = setTimeout(() => navigate(url, openInNewWindow), 1000);

			trackingOptions.eventCallback = (containerId, eventInformation) => {
				if (
					eventInformation &&
					eventInformation.tags &&
					eventInformation.tags[0] &&
					eventInformation.tags[0].status === 'success'
				) {
					clearTimeout(navigateTimeout);
					navigate(url, openInNewWindow);
				}
			};
			window.dataLayer.push(trackingOptions);
		} catch (error) {
			navigate(url, openInNewWindow);
		}
	},

	/**
	 * @param shoppingSku: Object, example: {shoppingSku: 'WDE14HE-F270-C01'}
	 */
	trackShoppingSKU(shoppingSku) {
		if (isTrackingEnabled()) {
			window.dataLayer.push(shoppingSku);
		}
	},

	trackOdoscopeEvent(event) {
		if (isTrackingEnabled()) {
			window.dataLayer.push(event);
		}
	},

	trackMasterFeedId(productJSON) {
		if (isTrackingEnabled()) {
			const masterFeedIdBuilder = new MasterFeedIdBuilder(productJSON);

			window.dataLayer.push({ masterFeedId: masterFeedIdBuilder.getMasterFeedId(getUrlParams()) });
		}
	},
};
