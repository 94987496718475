import core from './core';
import observer from '../helpers/events';
import { MOBILE, DESKTOP, DeviceManager } from './DeviceManager';
import { FixPagePosition } from '../helpers/FixPagePosition';
import { scrollPageTo } from '../helpers/dom-interact';

// TODO once this is moved to Typescript, please also adapt TODOs in WishlistCrossSellingLoader.ts
const OpenClose = core.extend({
	constructor(options) {
		if (!options) {
			throw new Error('parameter is missing');
		}

		this.config = {
			parent: '', // Optional. If provided, content should also be provided
			toggler: '', // Required. If it is the only one provided, the HTML element must contain an attribute "data-toggle" with the "id" of the target element
			content: '', // Optional. Required if parent is provided
			classOpen: '', // Required
			event: 'click',
			onlyOneAtTime: true,
			focus: false,
			useCSS: false,
			disabledOnDesktop: false,
			animSpeed: 450,
			forbidPageScroll: false,
			hasSeveralToggler: false,
		};

		this.deviceManager = new DeviceManager();

		jQuery.extend(true, this.config, options || {});

		if (
			this.config.toggler === '' ||
			this.config.classOpen === '' ||
			(this.config.parent !== '' && this.config.content === '')
		) {
			this.config = null;

			return;
		}

		_.bindAll(this, 'toggleElement');

		this.init();
	},

	init() {
		observer(this);

		this.attachEvents();
	},

	toggleElement(e) {
		e.preventDefault();
		e.stopPropagation();

		if (this.config.disabledOnDesktop === true && this.deviceManager.getCurrentState() >= DESKTOP) {
			return false;
		}

		let toggler = jQuery(e.currentTarget);
		const parent = this.config.parent !== '' && toggler.parents(this.config.parent);
		const content = parent ? parent.find(this.config.content) : jQuery(toggler.attr('data-toggle'));
		const isOpen = toggler.hasClass(this.config.classOpen);

		if (this.config.hasSeveralToggler) {
			toggler = parent.find(this.config.toggler);
		}

		this[isOpen ? 'close' : 'open'](toggler, content);
	},

	updateContext(toggler, content) {
		if (this.lastToggler && this.lastContent) {
			this.close(this.lastToggler, this.lastContent);
		}

		this.lastToggler = toggler;
		this.lastContent = content;
	},

	open(toggler, content) {
		if (this.config.onlyOneAtTime) {
			this.updateContext(toggler, content);
		}

		toggler.addClass(this.config.classOpen);

		if (this.config.useCSS) {
			content.addClass(this.config.classOpen).toggleClass(this.config.useCSS);
		} else {
			content.addClass(this.config.classOpen).stop().slideDown(this.config.animSpeed);
		}

		if (this.deviceManager.getCurrentState() === MOBILE && this.config.forbidPageScroll) {
			FixPagePosition.getInstance().registerComponent(this);
		}

		if (this.config.focus) {
			this.focusOnContent(content);
		}

		const parent = this.config.parent !== '' ? toggler.parents(this.config.parent).get(0) : undefined;

		this.publish('openClose:opened', parent);
	},

	close(toggler, content) {
		toggler.removeClass(this.config.classOpen);

		if (this.config.useCSS) {
			content.removeClass(this.config.classOpen).toggleClass(this.config.useCSS);
		} else {
			content.removeClass(this.config.classOpen).stop().slideUp(this.config.animSpeed);
		}

		this.lastToggler = null;
		this.lastContent = null;

		if (this.deviceManager.getCurrentState() === MOBILE && this.config.forbidPageScroll) {
			FixPagePosition.getInstance().unregisterComponent(this);
		}

		this.publish('openClose:closed');
	},

	focusOnContent(content) {
		jQuery('html, body').animate({ scrollTop: content.offset().top }, this.config.animSpeed);
	},

	attachEvents() {
		jQuery(this.config.toggler).off(this.config.event);
		jQuery(this.config.toggler).on(this.config.event, this.toggleElement);
	},
});

export const ScrollToTopOpenClose = OpenClose.extend({
	close(toggler, content) {
		this.constructor.__super__.close.call(this, toggler, content);
		scrollPageTo(0, this.config.animSpeed);
	},
});

export default OpenClose;
