"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessagePopupElementTags = exports.MessagePopupElementTypes = exports.MessagePopupElementIds = void 0;
var MessagePopupElementIds;
(function (MessagePopupElementIds) {
    MessagePopupElementIds["TITLE"] = "title";
    MessagePopupElementIds["TEXT"] = "text";
    MessagePopupElementIds["BUTTON"] = "button";
    MessagePopupElementIds["CLOSE_BUTTON"] = "closeButton";
    MessagePopupElementIds["OVERLAY"] = "overlay";
})(MessagePopupElementIds = exports.MessagePopupElementIds || (exports.MessagePopupElementIds = {}));
var MessagePopupElementTypes;
(function (MessagePopupElementTypes) {
    MessagePopupElementTypes["DEFAULT_BUTTON"] = "default";
    MessagePopupElementTypes["SECONDARY_BUTTON"] = "secondary";
})(MessagePopupElementTypes = exports.MessagePopupElementTypes || (exports.MessagePopupElementTypes = {}));
var MessagePopupElementTags;
(function (MessagePopupElementTags) {
    MessagePopupElementTags["P"] = "p";
    MessagePopupElementTags["LI"] = "li";
    MessagePopupElementTags["SPAN"] = "span";
    MessagePopupElementTags["DIV"] = "div";
})(MessagePopupElementTags = exports.MessagePopupElementTags || (exports.MessagePopupElementTags = {}));
