"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4NewsletterTracker = void 0;
var GA4Tracker_1 = require("./GA4Tracker");
var GA4NewsletterTracker = /** @class */ (function () {
    function GA4NewsletterTracker() {
    }
    GA4NewsletterTracker.trackNewsletterCTA = function (ctaPosition) {
        var newsletterEvent = {
            event: 'custom_event',
            event_name: 'newsletter_subscription_cta',
            cta_position: ctaPosition,
        };
        GA4Tracker_1.GA4Tracker.track(newsletterEvent);
    };
    GA4NewsletterTracker.trackNewsletterSuccess = function (ctaPosition) {
        var newsletterEvent = {
            event: 'custom_event',
            event_name: 'newsletter_subscription_success',
            cta_position: ctaPosition,
        };
        GA4Tracker_1.GA4Tracker.track(newsletterEvent);
    };
    return GA4NewsletterTracker;
}());
exports.GA4NewsletterTracker = GA4NewsletterTracker;
