import core from './core';
import { ScrollToTopOpenClose } from './open-close';
import Tracker from '../helpers/tracker';
import { TrackingData } from '../helpers/TrackingTypes';

const TopInfoBanner = core.extend({
	constructor(opts) {
		const element = opts.el || jQuery('.js-top-info-holder');

		if (!element.length) {
			throw new Error('element not found');
		}

		this.config = {
			el: element,
			closeBtnClass: '.js-close-btn',
			cookieName: element.attr('data-top-info-cookie-name'),
			expDays: parseInt(element.attr('data-top-info-expires-days')),
			trackingCategory: '',
		};

		_.extend(this.config, opts || {});

		this.init();
	},

	init() {
		this.openClose = new ScrollToTopOpenClose({
			parent: '.js-top-info-holder',
			toggler: '.js-opener',
			content: '.js-top-info-content',
			classOpen: 'is-open',
			hasSeveralToggler: true,
		});

		this.attachEvents();
	},

	attachEvents() {
		this.config.el.find(this.config.closeBtnClass).on('click', () => {
			this.onClose();
		});

		this.openClose.subscribe('openClose:opened', () => {
			this.tracking('open');
		});

		this.openClose.subscribe('openClose:closed', () => {
			this.tracking('close');
		});
	},

	onClose() {
		this.config.el.addClass('is-hidden');

		this.setCookie(this.config.cookieName);

		this.tracking('cancel');
	},

	setCookie(cookie) {
		const date = new Date();

		date.setTime(date.getTime() + this.config.expDays * 24 * 60 * 60 * 1000);

		document.cookie = `${cookie}=true; path=/; expires=${date.toUTCString()}`;
	},

	tracking(label) {
		Tracker.track({
			category: this.config.trackingCategory,
			action: TrackingData.ACTION_TOP_INFO_BANNER,
			label,
		});
	},
});

export default TopInfoBanner;
