"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductJsonParser = void 0;
var ProductJsonParser = /** @class */ (function () {
    function ProductJsonParser(productJSON) {
        this.productJSON = productJSON;
    }
    ProductJsonParser.prototype.getActiveFormat = function (customOptions) {
        if (customOptions.format) {
            return customOptions.format;
        }
        if (typeof this.productJSON.categoryPreConfig !== 'undefined') {
            if (typeof this.productJSON.categoryPreConfig.format !== 'undefined') {
                return this.productJSON.categoryPreConfig.format;
            }
        }
        if (typeof this.productJSON.preConfig.format !== 'undefined') {
            return this.productJSON.preConfig.format;
        }
        return '';
    };
    ProductJsonParser.prototype.getActiveConcreteProduct = function (customOptions) {
        var activeFormat = this.getActiveFormat(customOptions);
        var concreteProducts = this.productJSON.concreteProducts || [];
        return concreteProducts.find(function (product) { return product.format === activeFormat; });
    };
    ProductJsonParser.prototype.getPriceObjectPerQuantity = function (quantity, customOptions) {
        var concreteProduct = this.getActiveConcreteProduct(customOptions || {});
        if (concreteProduct && concreteProduct.prices) {
            var sortedPrices = concreteProduct.prices.sort(function (a, b) { return a.quantity - b.quantity; });
            if (sortedPrices.length > 0) {
                var currentPriceElement_1 = sortedPrices[0];
                concreteProduct.prices.forEach(function (priceObj) {
                    if (priceObj.quantity > +quantity) {
                        return;
                    }
                    currentPriceElement_1 = priceObj;
                });
                return currentPriceElement_1;
            }
        }
    };
    ProductJsonParser.prototype.getDefaultValueForProductOption = function (productOptionKey, customOptions) {
        var availableProductOptions = this.getAvailableProductOptions(productOptionKey, customOptions);
        if (availableProductOptions.length) {
            return availableProductOptions[0].key;
        }
        return '';
    };
    ProductJsonParser.prototype.getAvailableProductOptions = function (productOptionKey, customOptions) {
        var activeConcreteProduct = this.getActiveConcreteProduct(customOptions);
        if (activeConcreteProduct) {
            if (activeConcreteProduct.productOptions[productOptionKey]) {
                return activeConcreteProduct.productOptions[productOptionKey];
            }
        }
        return [];
    };
    ProductJsonParser.prototype.isOptionValueAvailable = function (productOptions, value) {
        return typeof productOptions.find(function (option) { return option.key === value; }) !== 'undefined';
    };
    return ProductJsonParser;
}());
exports.ProductJsonParser = ProductJsonParser;
