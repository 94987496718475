"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Xhr = exports.NO_RESPONSE = void 0;
var ContentType_1 = require("./ContentType");
var RequestMethod_1 = require("./RequestMethod");
var Sentry_1 = require("../../helpers/Sentry");
var STATUS_OK = 200;
var STATUS_MULTIPLE_CHOICES = 300;
exports.NO_RESPONSE = 0;
var Xhr = /** @class */ (function () {
    function Xhr() {
    }
    Xhr.getHtml = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        Xhr.send(Xhr.appendParametersToUrl(url, Xhr.toQueryString(data)), ContentType_1.ContentType.FORM_URL_ENCODED, RequestMethod_1.RequestMethod.GET)
                            .then(function (xhr) { return resolve(xhr.responseText); })
                            .catch(reject);
                    })];
            });
        });
    };
    Xhr.getJson = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        Xhr.send(Xhr.appendParametersToUrl(url, Xhr.toQueryString(data)), ContentType_1.ContentType.FORM_URL_ENCODED, RequestMethod_1.RequestMethod.GET)
                            .then(function (xhr) { return resolve(JSON.parse(xhr.responseText)); })
                            .catch(reject);
                    })];
            });
        });
    };
    Xhr.postFormData = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        Xhr.send(url, ContentType_1.ContentType.FORM_URL_ENCODED, RequestMethod_1.RequestMethod.POST, Xhr.toQueryString(data))
                            .then(function (xhr) { return resolve(JSON.parse(xhr.responseText)); })
                            .catch(reject);
                    })];
            });
        });
    };
    Xhr.logXhrPromiseRejection = function (xhrResponse, identifierMessage) {
        if (identifierMessage === void 0) { identifierMessage = 'no message provided'; }
        if (xhrResponse.status === exports.NO_RESPONSE) {
            return;
        }
        Sentry_1.SentryLogger.getInstance().sendInfoReport({
            message: "catch in " + identifierMessage + " status " + xhrResponse.status + " message " + xhrResponse.responseText,
        });
    };
    Xhr.toQueryString = function (data) {
        if (data === void 0) { data = {}; }
        return Object.keys(data)
            .map(function (key) { return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]); })
            .join('&');
    };
    Xhr.appendParametersToUrl = function (url, queryString) {
        var connector = url.includes('?') ? '&' : '?';
        return "" + url + connector + queryString;
    };
    Xhr.send = function (url, contentType, requestMethod, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var xhr = new XMLHttpRequest();
                        xhr.open(requestMethod, url, true);
                        xhr.setRequestHeader('Content-type', contentType);
                        xhr.setRequestHeader('Pragma', 'no-cache');
                        xhr.setRequestHeader('Cache-Control', 'no-cache');
                        xhr.onload = function () {
                            return xhr.status < STATUS_OK || xhr.status >= STATUS_MULTIPLE_CHOICES ? reject(xhr) : resolve(xhr);
                        };
                        xhr.onerror = function () { return reject(xhr); };
                        xhr.send(data);
                    })];
            });
        });
    };
    return Xhr;
}());
exports.Xhr = Xhr;
