export class ScrollerButton {
	constructor(el, cssStateClass, onClickCallback) {
		this.el = el;
		this.cssStateClass = cssStateClass;
		this.offsetWidth = 0;
		this.attachClickEvent(onClickCallback);
	}

	attachClickEvent(onClickCallback) {
		this.el.addEventListener('click', () => onClickCallback());
	}

	deactivate() {
		if (this.isActive()) {
			this.el.classList.add(this.cssStateClass);
		}
	}

	activate() {
		if (!this.isActive()) {
			this.el.classList.remove(this.cssStateClass);
		}
	}

	isActive() {
		return !this.el.classList.contains(this.cssStateClass);
	}

	getOffsetWidth() {
		return this.el.offsetWidth;
	}

	show() {
		this.el.classList.remove('hide');
	}
}
