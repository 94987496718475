import { getHTMLElement, getHTMLElements, getTotalWidthOfElementInPixels } from '../../../helpers/dom-interact';
import Tracker from '../../../helpers/tracker';
import { ScrollerButton } from './scroller-button';
import { DeviceManager } from '../../DeviceManager';
import { TrackingData } from '../../../helpers/TrackingTypes';
import { GA4PDPTracker } from '../../GA4/GA4PDPTracker';

export class Scroller {
	constructor(element, config) {
		this.el = element;
		try {
			this.viewport = getHTMLElement(this.el, config.classes.viewport);
			this.slides = getHTMLElements(this.viewport, config.classes.slide);
		} catch (e) {
			return;
		}

		this.slideWidth = getTotalWidthOfElementInPixels(this.slides[0]);

		this.trackingCategory = config.trackingCategory;

		if (!DeviceManager.isTouchDevice()) {
			this.initializeButtons(config.classes, config.buttonState);
			this.updateViewportWidth();
			this.updateButtonsState();
		}

		this.attachEvents();
	}

	attachEvents() {
		if (!DeviceManager.isTouchDevice()) {
			window.addEventListener('resize', () => this.updateScrollerUI());
		}

		if (this.trackingCategory) {
			for (const slide of this.slides) {
				const link = slide.querySelector('.js-tile-link');
				if (link) {
					link.addEventListener('click', (event) => this.onSlideClick(event, slide.dataset));
				}

				if (this.trackingCategory === TrackingData.PAGE_PDP) {
					slide.addEventListener('click', (event) => {
						const { currentTarget } = event;
						if (currentTarget.dataset && 'sku' in currentTarget.dataset) {
							GA4PDPTracker.trackReleatedProduct(currentTarget.dataset.sku);
						}
					});
				}
			}
		}
	}

	onSlideClick(event, dataset) {
		const { currentTarget } = event;
		event.preventDefault();

		this.trackClickOnSlide(currentTarget);
	}

	initializeButtons(classes, buttonState) {
		this.prevButton = new ScrollerButton(getHTMLElement(this.el, classes.prevButton), buttonState, () =>
			this.onClickPrevButton()
		);

		this.nextButton = new ScrollerButton(getHTMLElement(this.el, classes.nextButton), buttonState, () =>
			this.onClickNextButton()
		);

		this.prevButton.show();
		this.nextButton.show();
	}

	updateScrollerUI() {
		this.slideWidth = getTotalWidthOfElementInPixels(this.slides[0]);
		this.updateButtonsState();
		this.updateViewportWidth();
	}

	updateButtonsState() {
		if (this.shouldPrevButtonBeVisible()) {
			this.prevButton.activate();
		} else {
			this.prevButton.deactivate();
		}

		if (this.shouldNextButtonBeVisible()) {
			this.nextButton.activate();
		} else {
			this.nextButton.deactivate();
		}
	}

	updateViewportWidth() {
		const maxPossibleSlides = this.calculateMaxPossibleSlidesInViewport();

		let viewportWidth;

		if (maxPossibleSlides >= this.slides.length) {
			viewportWidth = this.calculateTotalSlidesWidth();
		} else {
			viewportWidth = maxPossibleSlides * this.slideWidth;
		}

		this.viewport.style.width = `${viewportWidth}px`;
		this.el.style.width = `${viewportWidth}px`;
	}

	getViewportWidth() {
		const viewportWidth = this.viewport.style.width.match(/\d+/);

		if (viewportWidth) {
			return parseInt(viewportWidth[0], 10);
		}

		return 0;
	}

	animateViewport(scrollLeft) {
		jQuery(this.viewport).animate({ scrollLeft }, () => this.updateButtonsState());
	}

	isViewportAnimated() {
		return jQuery(this.viewport).is(':animated');
	}

	onClickPrevButton() {
		if (this.isViewportAnimated()) {
			return;
		}

		const scrollPosition = this.getViewportScrollLeft();
		const viewportWidth = this.getViewportWidth();

		let scrollLeft = scrollPosition - viewportWidth;

		if (scrollLeft < 0) {
			scrollLeft = 0;
		}

		this.animateViewport(scrollLeft);
		this.updateButtonsState();
		this.trackClickOnButton('left');
		if (this.trackingCategory === TrackingData.PAGE_PDP) {
			GA4PDPTracker.trackRelatedProductArrow('Left');
		}
	}

	onClickNextButton() {
		if (this.isViewportAnimated()) {
			return;
		}

		const scrollPosition = this.getViewportScrollLeft();
		const viewportWidth = this.getViewportWidth();

		let scrollLeft = scrollPosition + viewportWidth;
		const maxScrollLeft = this.calculateTotalSlidesWidth();

		if (scrollLeft > maxScrollLeft) {
			scrollLeft = maxScrollLeft;
		}

		this.animateViewport(scrollLeft);
		this.updateButtonsState();
		this.trackClickOnButton('right');
		if (this.trackingCategory === TrackingData.PAGE_PDP) {
			GA4PDPTracker.trackRelatedProductArrow('Right');
		}
	}

	getViewportScrollLeft() {
		return this.viewport.scrollLeft;
	}

	shouldPrevButtonBeVisible() {
		return this.getViewportScrollLeft() > 0;
	}

	shouldNextButtonBeVisible() {
		return (
			this.calculateTotalSlidesWidth() - this.getViewportScrollLeft() - this.getViewportWidth() >= this.slideWidth
		);
	}

	calculateMaxPossibleSlidesInViewport() {
		return Math.floor((this.el.parentElement.offsetWidth - this.getSizeButtons()) / this.slideWidth) || 1;
	}

	calculateTotalSlidesWidth() {
		return this.slideWidth * this.slides.length;
	}

	getSizeButtons() {
		const prevButtonWidth = this.prevButton.getOffsetWidth();
		const nextButtonWidth = this.nextButton.getOffsetWidth();

		return prevButtonWidth + nextButtonWidth;
	}

	trackClickOnButton(label) {
		if (!this.trackingCategory) {
			return;
		}
		Tracker.track({
			category: this.trackingCategory,
			action: TrackingData.ACTION_PRODUCT_SLIDER_1_SLIDE,
			label,
		});
	}

	trackClickOnSlide(element) {
		Tracker.trackAndNavigate(
			{
				category: this.trackingCategory,
				action: element.getAttribute('data-tracking-action'),
				label: element.href,
			},
			element.href
		);
	}
}
