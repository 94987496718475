"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Glossary = void 0;
var Glossary = /** @class */ (function () {
    function Glossary() {
    }
    Glossary.NO_TAG_SKU = 'T00';
    Glossary.TAG_SKU = 'T01';
    Glossary.NO_DIECUT_SKU = 'D00';
    Glossary.NO_RIBBON_AND_TAG_DIECUT = 'D50';
    Glossary.RIBBON_AND_TAG_DIECUT = 'D53';
    Glossary.NO_REFINEMENT_SKU = 'V00';
    Glossary.NO_FRAME_SKU = 'WF00';
    Glossary.NO_FRAME_TYPE = 'B';
    Glossary.PREFIX_COLORFUL_RIBBON = 'RC';
    Glossary.DEFAULT_ITEMS = 10;
    return Glossary;
}());
exports.Glossary = Glossary;
