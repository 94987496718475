"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newsletterInit = void 0;
var GA4NewsletterTracker_1 = require("../GA4/GA4NewsletterTracker");
var newsletterInit = function (pageName) {
    var newsletterForms = Array.from(document.querySelectorAll('form[name="ProfileForm"]'));
    newsletterForms.forEach(function (newsletterForm) {
        newsletterForm.addEventListener('submit', function (evt) {
            GA4NewsletterTracker_1.GA4NewsletterTracker.trackNewsletterCTA(pageName);
            var emailField = newsletterForm.querySelector('[name="inp_3"]');
            if ((emailField === null || emailField === void 0 ? void 0 : emailField.value) === '') {
                // TODO : handle a translated error coming from SB template
                alert('Bitte geben Sie eine E-Mail Adresse ein.');
                evt.preventDefault();
                return false;
            }
            else {
                return true;
            }
        });
    });
};
exports.newsletterInit = newsletterInit;
