import core from './core';
import { getUrlParams } from '../helpers/handle-url';
import { reloadPage } from '../helpers/dom-interact';
import PageTypes from '../helpers/page-types';
import Tracker from '../helpers/tracker';
import { DeviceManager, MOBILE } from './DeviceManager';
import { Xhr } from '../Modules/HttpRequest';
import { ApiEndpoints } from '../Configurations/ApiEndpoints';
import { Glossary } from '../helpers/Glossary';
import { EnhanceEcommerce } from '../helpers/EnhanceEcommerce';
import { TrackingData } from '../helpers/TrackingTypes';
import { GA4EcommerceTracker } from './GA4/GA4EcommerceTracker';
import { GA4PDPTracker } from './GA4/GA4PDPTracker';

/**
 * @param productOption
 * @returns {string}
 */
function getProductOptionValue(productOption) {
	return productOption && productOption.length ? productOption[0].key : '';
}

/**
 * @param option
 * @returns {string}
 */
function getNonRequiredOption(option) {
	return option || '';
}

const ConfiButton = core.extend({
	constructor(opts) {
		if (!opts || !opts.el || !opts.data) {
			throw new Error('params are missing');
		}
		this.productData = opts.data;
		this.deviceManager = new DeviceManager();

		this.init(opts);
	},

	init(options) {
		this.prepareComponentOptions(options);

		if (options.data.template_format) {
			const findProduct = _.find(
				options.data.concreteProducts,
				(product) => product.format === options.data.template_format
			);

			if (findProduct) {
				this.data.product = findProduct;
			}
		}

		this.prepareData(options.data);

		this.data.view = options.mode || PageTypes.PDP;
		this.data.isCrosssellingItem = options.isCrosssellingItem || false;
		this.cookies = options.cookies;

		this.addButton(options.el);
		const isKnownPlatform = DeviceManager.isAndroid() || DeviceManager.isIOS();
		if (isKnownPlatform && ['KAM_DE', 'KAM_AT', 'KAM_CH'].includes(this.data.storeCode)) {
			return;
		}
		_.each(options.el, (button) => {
			jQuery(button).removeClass('js-real-door-popup');
		});
	},

	addButton(el) {
		this.buttons = this.initButtons(el);
		this.update();
	},

	prepareComponentOptions(options) {
		_.extend(this, {
			popup: options.popup,
			jessiUrl: options.data.jessiUrl,
			hiddenDiecuts: [
				'D51',
				'D52',
				'D54',
				'D55',
				'D56',
				'D57',
				'D58',
				'D59',
				'D41',
				'D60',
				'D61',
				'D62',
				'D63',
				'D70',
				'D71',
			],
			calendarsFormats: [
				'F400',
				'F410',
				'F411',
				'F415',
				'F420',
				'F430',
				'F431',
				'F432',
				'F433',
				'F434',
				'F435',
				'F436',
				'F437',
			],
			data: {
				product:
					_.find(
						options.data.concreteProducts,
						(product) => product.format === options.data.preConfig.format
					) || options.data.concreteProducts[0],
			},
		});

		if (options.overlay) {
			this.overlay = options.overlay;
		}

		if (options.data.hash) {
			this.hash = options.data.hash;
		}
	},

	prepareData(data) {
		this.data = _.extend(this.data, {
			template_format: this.data.product.format,
			template_format_id: this.data.product.format, // duplication. tbr
			product_id: data.abstractSku,
			template_design: data.abstractSku,
			locale: data.externalStoreCode || 'de_DE',
			storeCode: data.storeCode,
			quantity: data.quantity || _.keys(this.data.product.prices)[0],
			template_color: data.template_color || getProductOptionValue(this.data.product.productOptions.color),
			template_color_id: data.template_color_id || getProductOptionValue(this.data.product.productOptions.color), // duplication. tbr
			template_paper: data.template_paper || getProductOptionValue(this.data.product.productOptions.papers),
			template_paper_id: data.template_paper_id || getProductOptionValue(this.data.product.productOptions.papers), // duplication. tbr
			template_box: data.template_box || getProductOptionValue(this.data.product.productOptions.box),
			template_box_id: data.template_box_id || getProductOptionValue(this.data.product.productOptions.box),
			amountOfPhotos: data.amountOfPhotos,
			template_amount_of_photos:
				data.template_amount_of_photos ||
				getProductOptionValue(this.data.product.productOptions.amountsOfPhotos),
			template_amount_of_photos_id:
				data.template_amount_of_photos_id ||
				getProductOptionValue(this.data.product.productOptions.amountsOfPhotos),
			ribbon: data.ribbon || getProductOptionValue(this.data.product.productOptions.ribbons),
			numberOfPages: data.numberOfPages || getProductOptionValue(this.data.product.productOptions.numberOfPages),
			groupKey: getNonRequiredOption(data.groupKey),
			configurationID: getNonRequiredOption(data.configurationID),
			template_diecut: getNonRequiredOption(data.template_diecut),
			template_diecut_id: getNonRequiredOption(data.template_diecut_id), // duplication. tbr
			lacquer_sku: getNonRequiredOption(data.lacquer_sku),
			tag_sku: getNonRequiredOption(data.tag_sku),
			tag_diecut: getNonRequiredOption(data.tag_diecut),
			pass_through: data.pass_through || this.updatePassThroughParams(data),
			quote_item_id: data.quote_item_id || 0,
			parent_quote_item_id: data.parent_quote_item_id,
			wishlist_item_id: data.wishlist_item_id,
			shopLocation: window.location.host,
			new: 'true',
		});
	},
	// GA4 Tracking
	trackAddToCart() {
		const customOptions = this.getRequestData();
		const item = EnhanceEcommerce.buildGA4Item(this.productData, 1, null, null, {
			...customOptions,
			catergoryName: null,
		});
		GA4EcommerceTracker.trackAddToCart([item]);
	},

	initButtons(els) {
		return _.map(els, (el) => this.setModes({ el: jQuery(el) }));
	},

	setModes(button) {
		button.action = button.el.parent().hasClass('edit-cta') ? 'update' : 'add';

		button.isPrintService = button.el.hasClass('print-service');

		button.isConfi = !button.el.hasClass('no-confi') && !button.isPrintService;

		const hardcodedModes = button.isConfi ? this.checkHardcodedModes() : {};
		const isIE = DeviceManager.detectIE();
		const isMobile = this.data.product.mobile ? true : !this.detectMobile();

		if (_.keys(hardcodedModes).length === 0) {
			button.isJessi = button.isConfi && this.data.product.jessi && !isIE && isMobile && !button.isPrintService;

			button.disable = button.isConfi && !button.isJessi && !this.isPrintService;
		} else {
			_.extend(button, hardcodedModes);
		}

		this.attachEvents(button);

		return button;
	},

	checkHardcodedModes() {
		const urlParams = getUrlParams(['confi', 'etm']);
		const modes = {};

		modes.isJessi = true;

		if (urlParams.etm === 'true') {
			this.data.etm = urlParams.etm;
			modes.isJessi = true;
		}

		return modes;
	},

	attachEvents(button) {
		button.el.off('click').attr('data-href', '').removeClass('disabled');
		if (!button.isConfi && !button.isPrintService) {
			button.el.on('click', (e) => {
				if (this.data.view === PageTypes.WISHLIST) {
					this.trackAddToCart();
					jQuery(this.overlay).removeClass('is-hidden');
					return;
				}
				if (this.data.view === PageTypes.BASKET) {
					this.trackAddToCart();
					Tracker.track({
						category: TrackingData.PAGE_BASKET,
						action: TrackingData.ACTION_ADD_TO_BASKET,
						label: this.data.product.concreteSku,
					});
				}
				if (this.data.view === PageTypes.CS) {
					const trackingAction =
						button.action === 'add'
							? TrackingData.ACTION_CROSSSELLING_CSBUTTON_TO_BASKET
							: TrackingData.ACTION_CROSSSELLING_CSBUTTON_SAVE_CHANGE;

					if (trackingAction === TrackingData.ACTION_CROSSSELLING_CSBUTTON_TO_BASKET) {
						this.trackAddToCart();
					}

					Tracker.track({
						category: TrackingData.PAGE_CROSSSELLING,
						action: trackingAction,
						label: this.data.product.concreteSku,
					});
				}
				if (this.data.view === PageTypes.CS) {
					button.el.addClass('disable');
				}
				if (!button.el.hasClass('popup')) {
					this.sendAjax(e, button);
				}
			});
		} else if (button.isPrintService) {
			button.el.on('click', () => this.getShopConfiguration());
		} else if (button.disable) {
			if (DeviceManager.detectIE()) {
				button.el.addClass('disabled ie-disabled');

				button.el.on('click', (e) => e.preventDefault());
			} else {
				button.el.addClass('hide mobile-disabled');
			}
		}
		if (this.data.view === PageTypes.PDP || this.data.view === PageTypes.CS) {
			button.el.on('click', (e) => {
				e.preventDefault();

				if (this.data.view === PageTypes.CS && !e.currentTarget.classList.contains('no-confi')) {
					this.trackNavigateToJessi();
				}

				const element = e.currentTarget;
				const customOptions = this.getRequestData();
				const url = element.getAttribute('data-href');
				const eventType = element.classList.contains('no-confi')
					? 'eec-add-to-basket'
					: 'eec-open-configurator';

				if (eventType === 'eec-add-to-basket') {
					this.trackAddToCart();
				}

				// RealDoor to Kartenmacherei App required only from PDP
				// Apparently the ConfiButton class is used in many pages and has a lot of responsibilities :(
				const realDoorClassName = 'js-real-door-popup';
				if (this.data.view === PageTypes.PDP && !!this.cookies) {
					// cookie name updated from "downloadAppModalShown" to "downloadAppModalShown_1" to reset for new Posters products
					if (this.cookies.checkIfCookieSet('downloadAppModalShown_1')) {
						element.classList.remove(realDoorClassName);
					}
				}

				const hasRealDoorPopup = element.classList.contains(realDoorClassName);
				const isRealPdpPage = this.data.view === PageTypes.PDP && typeof productJSON !== 'undefined';

				if (typeof productJSON !== 'undefined' && !hasRealDoorPopup) {
					EnhanceEcommerce.trackGoToConfigurator(productJSON, customOptions, eventType, url);
				}

				if (isRealPdpPage) {
					GA4PDPTracker.trackPersonalizedProduct(this.data);
				}
			});
		}
	},

	trackNavigateToJessi() {
		Tracker.track({
			category: TrackingData.PAGE_CROSSSELLING,
			action: TrackingData.ACTION_CROSSSELLING_CSBUTTON_OPEN_CONFIGURATOR,
			label: this.data.product.concreteSku,
		});
	},

	getShopConfiguration() {
		jQuery(this.overlay).removeClass('is-hidden');

		const data = {
			key: '212d60fdbda0165e7971aa6a51b655a2',
			product_id: this.data.product_id,
			template_format_id: this.data.template_format_id,
			template_color_id: this.data.template_color_id,
			pass_through: '{}',
			new: 'true',
		};

		Xhr.postFormData(ApiEndpoints.ACTIONS_AND_ITEM_INFO, data)
			.then((response) => this.createProductFolder(response))
			.catch((xhrResponse) => Xhr.logXhrPromiseRejection(xhrResponse, 'ConfiButton on getShopConfiguration'));
	},

	createProductFolder(response) {
		const contentExpressLocation = this.buildContentExpressUrl(this.data.shopLocation);

		this.data.configurationID = response.configurationID;

		const fetchPath = `${contentExpressLocation}connect/${response.configurationID}/${this.data.template_design}/${this.data.template_format}/${this.data.template_color}/${this.data.locale}/1`;

		Xhr.getJson(fetchPath)
			.then(() => this.addToCart())
			.catch((xhrResponse) => Xhr.logXhrPromiseRejection(xhrResponse, 'ConfiButton on createProductFolder'));
	},

	buildContentExpressUrl(shopLocation) {
		const devEnvPrefixes = ['dev.', 'dev-', 'stage.', 'local.', 'test.', 'preview.'];
		const isProduction = !shopLocation.match(new RegExp(devEnvPrefixes.join('|')));
		const pathSuffix = 'kartenmacherei.de/';
		const pathPrefix = isProduction ? '' : 'dev-';

		return `https://${pathPrefix}content-express.${pathSuffix}`;
	},

	addToCart(e) {
		if (e) {
			e.preventDefault();
		}

		const data = {
			product: this.data.template_design,
			color: this.data.template_color,
			format: this.data.template_format,
			lacquer: this.data.lacquer_sku,
			diecut: this.data.template_diecut,
			paper: this.data.template_paper,
			quantity: this.data.quantity,
			configurationID: this.data.configurationID,
		};

		Xhr.postFormData(ApiEndpoints.ADD_TO_BASKET, data)
			.then(() => {
				window.location =
					this.data.view === PageTypes.PDP
						? `${window.location.protocol}//${window.location.host}/checkout/cart/`
						: window.location;
			})
			.catch((xhrResponse) => Xhr.logXhrPromiseRejection(xhrResponse, 'ConfiButton on addToCart'));
	},

	setActiveProduct(product, preselection) {
		this.data.product = product;

		this.data.template_format = product.format;

		this.data.template_format_id = product.format; // duplication. tbr

		if (!(product.productOptions.diecuts || []).length) {
			this.data.template_diecut = '';

			this.data.template_diecut_id = ''; // duplication. tbr
		} else {
			this.checkHiddenDiecut(product.productOptions.diecuts);
		}

		if (!preselection.tag || preselection.tag === Glossary.NO_TAG_SKU) {
			this.data.tag_diecut = '';

			this.data.tag_sku = '';
		}

		this.data.wooden_block = product.productOptions.woodenBlocks ? product.productOptions.woodenBlocks[0].key : '';

		this.data.wooden_frame = product.productOptions.frames ? product.productOptions.frames[0].key : '';

		this.data.outer_paper = product.productOptions.outerPapers ? product.productOptions.outerPapers[0].key : '';

		this.data.template_paper = product.productOptions.papers ? product.productOptions.papers[0].key : '';

		this.data.template_paper_id = this.data.template_paper;

		this.data.template_box_id = product.productOptions.box ? this.data.product.productOptions.box[0].key : '';

		this.data.template_box = this.data.template_box_id;

		this.data.template_amount_of_photos_id = product.productOptions.amountsOfPhotos
			? this.data.product.productOptions.amountsOfPhotos[0].key
			: '';

		this.data.lacquer_sku = product.productOptions.refinements ? product.productOptions.refinements[0].key : '';

		if (product.productOptions.ribbons) {
			if (preselection.ribbon) {
				this.data.ribbon = preselection.ribbon;
			} else {
				this.data.ribbon = product.productOptions.ribbons[0].key;
			}
		} else {
			this.data.ribbon = '';
		}

		if (product.productOptions.numberOfPages) {
			if (preselection.numberOfPages) {
				this.data.number_pages = preselection.numberOfPages;
			} else {
				this.data.number_pages = product.productOptions.numberOfPages[0].key;
			}
		} else {
			this.data.number_pages = '';
		}

		this.data.inner_pages = product.productOptions.innerPages ? product.productOptions.innerPages[0].key : '';

		_.each(this.buttons, (button) => this.setModes(button));
	},

	checkHiddenDiecut(diecuts) {
		_.each(
			diecuts,
			function (diecutObj) {
				const diecutKey = ~this.hiddenDiecuts.indexOf(diecutObj.key) ? diecutObj.key : '';

				this.data.template_diecut = diecutKey;

				this.data.template_diecut_id = diecutKey;
			},
			this
		);
	},

	update(obj) {
		if (obj) {
			_.extend(this.data, obj);
		}
		this.data.pass_through = this.updatePassThroughParams(this.data);

		const data = _.extend({}, this.data);

		_.each(
			this.buttons,
			function (button) {
				if (button.action === 'update') {
					data.view = PageTypes.BASKET;
				}
				this.jessiLink = `${this.jessiUrl}?p=${btoa(this.jsonToQuery(data))}${
					this.hash ? `&h=${this.hash}` : ''
				}`;

				if (this.popup) {
					this.popup.jessiUrl = this.jessiLink;
				}

				const actualUrl =
					this.data.view !== PageTypes.WISHLIST
						? '#'
						: `/wishlist/index/wishlistItemToBasket/itemId/${this.data.wishlist_item_id}`;

				button.el.attr('data-href', button.isJessi ? this.jessiLink : actualUrl);
			},
			this
		);
	},

	sendAjax(e, button) {
		if (e) {
			e.preventDefault();
		}

		let url;

		if (this.data.view === PageTypes.WISHLIST) {
			url = ApiEndpoints.UPDATE_ITEM;
		} else {
			url = ApiEndpoints.UPDATE_BASKET;
		}

		if (button !== undefined) {
			button.el.parents('.crossselling-product, .ordered-product-container').addClass('added').click();
			if (this.data.isCrosssellingItem) {
				url = ApiEndpoints.ADD_TO_WISHLIST;
			} else {
				url = button.action === 'add' ? ApiEndpoints.ADD_TO_BASKET : ApiEndpoints.UPDATE_BASKET;
			}
		}

		jQuery(this.overlay).removeClass('is-hidden');

		Xhr.postFormData(url, this.getRequestData())
			.then((response) => this.handleResponse(response, button))
			.catch((xhrResponse) => Xhr.logXhrPromiseRejection(xhrResponse, 'ConfiButton on sendAjax'));
	},

	handleResponse(response, button) {
		jQuery(this.overlay).addClass('is-hidden');

		if (this.data.view === PageTypes.PDP) {
			window.location = `${window.location.protocol}//${window.location.host}/checkout/cart/`;
		} else if (button !== undefined && button.action === 'add') {
			button.el.parents('.crossselling-product').addClass('added');

			jQuery(document).click(); // close the tooltip by clicking out
		}

		if (this.data.view === PageTypes.CS && response.quote_item_id !== -1) {
			window.location = response.url;
		}
		if (this.data.view === PageTypes.BASKET) {
			window.location = response.url;
		}
		if (this.data.view === PageTypes.WISHLIST || this.data.isCrosssellingItem) {
			reloadPage();
		}
	},

	getRequestData() {
		const possibleDataKeysAndMapping = {
			template_color: 'color',
			template_format: 'format',
			lacquer_sku: 'lacquer',
			template_diecut: 'diecut',
			template_paper: 'paper',
			quote_item_id: 'quote_item_id',
			configurationID: 'configurationID',
			view: 'view',
			wooden_block: 'woodenBlock',
			outer_paper: 'outerPaper',
			template_box: 'template_box',
			template_amount_of_photos_id: 'template_amount_of_photos',
			ribbon: 'ribbon',
			numberOfPages: 'number_pages',
			innerPages: 'innerPages',
			wishlist_item_id: 'wishlistItem',
		};

		const data = {
			product: this.data.template_design,
			quantity: this.data.quantity,
		};

		const woodenFrame = this.data.frame || this.data.wooden_frame;

		_.each(Object.keys(possibleDataKeysAndMapping), (key) => {
			if (this.data[key]) {
				data[possibleDataKeysAndMapping[key]] = this.data[key];
			}
		});

		if (this.data.parent_quote_item_id && this.data.view !== PageTypes.BASKET) {
			data.parent_quote_item_id = this.data.parent_quote_item_id;
		}

		if (woodenFrame) {
			data.frame = woodenFrame;
		}

		return data;
	},

	detectMobile() {
		const isCalendar = ~this.calendarsFormats.indexOf(this.data.product.format);

		if (isCalendar) {
			return DeviceManager.hasOrientation();
		}

		return this.deviceManager.getCurrentState() === MOBILE && DeviceManager.hasOrientation();
	},

	updatePassThroughParams(data) {
		const passThroughObj = {};

		passThroughObj.useWoodenBlockSku = data.wooden_block;

		if (data.printing_quality) {
			passThroughObj.usePrintingQualitySku = data.printing_quality;
		}

		if (!!data.wooden_frame && data.wooden_frame.substr(0, 1) === 'W') {
			passThroughObj.useWoodenFrameSku = data.wooden_frame;
		}

		if (!!data.wooden_frame && data.wooden_frame.substr(0, 1) === 'D') {
			passThroughObj.useDoorFrameSku = data.wooden_frame;
		}

		if (data.outer_paper) {
			passThroughObj.useOuterPaperSku = data.outer_paper;
		}

		if (data.quantity) {
			passThroughObj.useQuantity = data.quantity;
		}

		if (data.innerPages) {
			passThroughObj.innerPages = data.innerPages;
		}

		return _.isEmpty(passThroughObj) ? this.data.pass_through || '' : JSON.stringify(passThroughObj);
	},
});

export default ConfiButton;
