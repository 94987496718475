"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnhanceEcommerce = void 0;
var _ = __importStar(require("underscore"));
var dom_interact_1 = require("./dom-interact");
var ProductJsonParser_1 = require("./ProductJsonParser");
var TrackingTypes_1 = require("./TrackingTypes");
var GA4EcommerceTracker_1 = require("../components/GA4/GA4EcommerceTracker");
var GA4TrackerVars_1 = require("../components/GA4/GA4TrackerVars");
var EnhanceEcommerce = /** @class */ (function () {
    function EnhanceEcommerce() {
    }
    EnhanceEcommerce.isTrackingEnabled = function () {
        return !(typeof dataLayer === 'undefined');
    };
    EnhanceEcommerce.buildGA4ItemFromCommonTrackingData = function (commonTrackingData, price, quantity, index, itemListName, itemListId, customizedOptions) {
        var customItemCategoryKey = commonTrackingData.id.slice(-2);
        var itemCategory = (customizedOptions === null || customizedOptions === void 0 ? void 0 : customizedOptions.categoryName) || '';
        var longSku = commonTrackingData.id;
        ['dimension13', 'variant'].forEach(function (key) {
            longSku += '-' + commonTrackingData[key];
        });
        return {
            item_name: commonTrackingData.name,
            item_id: commonTrackingData.id,
            affiliation: '',
            coupon: '',
            currency: (0, GA4TrackerVars_1.getCurrency)(),
            discount: 0,
            item_brand: commonTrackingData.brand,
            item_category: itemCategory,
            item_category2: '',
            item_category3: '',
            item_category4: '',
            item_category5: '',
            item_variant: commonTrackingData.variant,
            item_list_name: itemListName,
            item_list_id: itemListId,
            index: index,
            location_id: '',
            quantity: quantity,
            price: price,
            custom_item_format: commonTrackingData.variant,
            custom_item_long_sku: longSku,
            custom_item_category_key: customItemCategoryKey, // Custom field
        };
    };
    EnhanceEcommerce.buildGA4Item = function (productJSON, index, itemListName, itemListId, customizedOptions) {
        var commonTrackingData = EnhanceEcommerce.getCommonTrackingData(productJSON, customizedOptions);
        var quantity = productJSON.quantity || productJSON.preConfig.quantity;
        var productJsonParser = new ProductJsonParser_1.ProductJsonParser(productJSON);
        var priceObject = productJsonParser.getPriceObjectPerQuantity(quantity, customizedOptions);
        var price = priceObject ? priceObject.price / 100 : 0;
        return EnhanceEcommerce.buildGA4ItemFromCommonTrackingData(commonTrackingData, price, quantity, index, itemListName, itemListId, customizedOptions);
    };
    EnhanceEcommerce.trackProductClick = function (productJSON, category, position, customizedOptions, url) {
        if (!EnhanceEcommerce.isTrackingEnabled()) {
            (0, dom_interact_1.navigate)(url);
        }
        try {
            // GA4 Tracking
            var clickItem = EnhanceEcommerce.buildGA4Item(productJSON, position, 'Search Results', 'searchresults', __assign(__assign({}, customizedOptions), { categoryName: category }));
            GA4EcommerceTracker_1.GA4EcommerceTracker.trackSelectItem('searchresults', 'Search Results', clickItem);
            var navigateTimeout_1 = setTimeout(function () { return (0, dom_interact_1.navigate)(url); }, 1000);
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: 'eec-click',
                ecommerce: {
                    click: {
                        actionField: { list: 'searchresults' },
                        products: [
                            __assign({ category: category, position: position }, EnhanceEcommerce.getCommonTrackingData(productJSON, customizedOptions)),
                        ],
                    },
                },
                eventCallback: function () {
                    clearTimeout(navigateTimeout_1);
                    (0, dom_interact_1.navigate)(url);
                },
            });
        }
        catch (e) {
            (0, dom_interact_1.navigate)(url);
        }
    };
    EnhanceEcommerce.trackProductImpressions = function (productsJsonList) {
        if (EnhanceEcommerce.isTrackingEnabled()) {
            var impressionsData = productsJsonList
                .filter(function (productJSON) { return !productJSON.contentInsert; })
                .map(function (productJSON) {
                var concreteProduct = productJSON.concreteProducts.find(function (product) {
                    return product.format === (productJSON.categoryPreConfig.format || productJSON.preConfig.format);
                });
                return __assign(__assign({ list: 'searchresults', category: productJSON.category, position: productJSON.position }, EnhanceEcommerce.getCommonTrackingData(_.omit(productJSON, 'concreteProducts'), {})), { dimension14: concreteProduct && concreteProduct.isNew ? 'true' : 'false' });
            });
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: 'eec-impression',
                ecommerce: {
                    impressions: impressionsData,
                },
            });
            // GA4 Tracking
            EnhanceEcommerce.trackGA4ViewItemList('searchresults', 'Category Page', productsJsonList);
            return [];
        }
        return productsJsonList;
    };
    EnhanceEcommerce.trackCrosssellingData = function (productsJsonList) {
        if (EnhanceEcommerce.isTrackingEnabled()) {
            var impressionsData = productsJsonList
                .filter(function (productJSON) { return !productJSON.contentInsert; })
                .map(function (productJSON) { return ({
                list: 'crossselling',
                category: TrackingTypes_1.TrackingData.PAGE_CROSSSELLING,
                action: TrackingTypes_1.TrackingData.ACTION_CROSSSELLING_SEE_PRODUCT,
                label: productJSON.abstractSku,
            }); });
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: 'eec-impression',
                ecommerce: {
                    impressions: impressionsData,
                },
            });
            // GA4 Tracking
            EnhanceEcommerce.trackGA4ViewItemList('crossselling', 'Cross Selling', productsJsonList);
            return [];
        }
        return productsJsonList;
    };
    EnhanceEcommerce.trackProductDetailView = function (productJSON) {
        if (EnhanceEcommerce.isTrackingEnabled()) {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: 'eec-detail',
                ecommerce: {
                    detail: {
                        products: [
                            __assign({ category: '' }, EnhanceEcommerce.getCommonTrackingData(productJSON, {})),
                        ],
                    },
                },
            });
            // GA4 Tracking
            var ga4Item = EnhanceEcommerce.buildGA4Item(productJSON, 1, 'Product Details', 'productdetails', {
                categoryName: productJSON.primaryCategory ? productJSON.primaryCategory.identifier : '',
            });
            GA4EcommerceTracker_1.GA4EcommerceTracker.trackViewItem([ga4Item]);
        }
    };
    EnhanceEcommerce.trackGoToConfigurator = function (productJSON, customizedOptions, eventType, url) {
        if (!EnhanceEcommerce.isTrackingEnabled()) {
            (0, dom_interact_1.navigate)(url);
        }
        try {
            var navigateTimeout_2 = setTimeout(function () { return (0, dom_interact_1.navigate)(url); }, 1000);
            var quantity = parseInt(customizedOptions.quantity) || productJSON.preConfig.quantity;
            dataLayer.push({
                event: eventType,
                ecommerce: {
                    add: {
                        products: [
                            __assign(__assign({ category: '', quantity: quantity }, EnhanceEcommerce.getCommonTrackingData(productJSON, customizedOptions)), { dimension6: productJSON.hasSample && quantity === 1 ? 'true' : 'false' }),
                        ],
                    },
                },
                eventCallback: function () {
                    clearTimeout(navigateTimeout_2);
                    (0, dom_interact_1.navigate)(url);
                },
            });
        }
        catch (e) {
            (0, dom_interact_1.navigate)(url);
        }
    };
    EnhanceEcommerce.trackFakeGoToConfiguratorAction = function (productJSON, customizedOptions, eventType) {
        var quantity = parseInt(customizedOptions.quantity) || productJSON.preConfig.quantity;
        dataLayer.push({
            event: eventType,
            ecommerce: {
                add: {
                    products: [
                        __assign(__assign({ category: '', quantity: quantity }, EnhanceEcommerce.getCommonTrackingData(productJSON, customizedOptions)), { dimension6: productJSON.hasSample && quantity === 1 ? 'true' : 'false' }),
                    ],
                },
            },
        });
    };
    EnhanceEcommerce.getCommonTrackingData = function (productJSON, customizedOptions) {
        var productJsonParser = new ProductJsonParser_1.ProductJsonParser(productJSON);
        var _a = productJSON.abstractSku, abstractSku = _a === void 0 ? '' : _a, _b = productJSON.categoryPreConfig, categoryPreConfig = _b === void 0 ? {} : _b, _c = productJSON.preConfig, preConfig = _c === void 0 ? {} : _c, groupName = productJSON.groupName, name = productJSON.name;
        var activeFormat = productJsonParser.getActiveFormat(customizedOptions);
        var concreteProduct = productJsonParser.getActiveConcreteProduct(customizedOptions);
        var isNew = concreteProduct && concreteProduct.isNew ? 'true' : 'false';
        return __assign(__assign({ id: abstractSku, name: "".concat(groupName, " \"").concat(name, "\""), brand: abstractSku.length === 7 ? abstractSku.slice(0, 5) : '', variant: activeFormat }, EnhanceEcommerce.mapProductOptionsToDimensions(categoryPreConfig, preConfig, customizedOptions, concreteProduct ? concreteProduct.productOptions : {})), { dimension14: isNew });
    };
    EnhanceEcommerce.mapProductOptionsToDimensions = function (categoryPreConfig, preConfig, customizedOptions, productOptions) {
        if (categoryPreConfig === void 0) { categoryPreConfig = {}; }
        if (preConfig === void 0) { preConfig = {}; }
        if (customizedOptions === void 0) { customizedOptions = {}; }
        if (productOptions === void 0) { productOptions = {}; }
        var mapping = {
            dimension2: 'diecut',
            dimension3: 'refinement',
            dimension4: 'paper',
            dimension5: 'woodenBlock',
            dimension7: 'frame',
            dimension8: 'amountOfPhotos',
            dimension9: 'ribbon',
            dimension10: 'tag',
            dimension11: 'outerPaper',
            dimension12: 'box',
            dimension13: 'color',
        };
        var customDimensions = {
            dimension2: '',
            dimension3: '',
            dimension4: '',
            dimension5: '',
            dimension7: '',
            dimension8: '',
            dimension9: '',
            dimension10: '',
            dimension11: '',
            dimension12: '',
            dimension13: '',
        };
        Object.keys(mapping).map(function (dimension) {
            var optionName = mapping[dimension];
            var defaultValue = '';
            if (productOptions["".concat(optionName, "s")] && productOptions["".concat(optionName, "s")].length) {
                defaultValue = productOptions["".concat(optionName, "s")][0].key;
            }
            else if (productOptions[optionName] && productOptions[optionName].length) {
                defaultValue = productOptions[optionName].key;
            }
            customDimensions[dimension] =
                customizedOptions[optionName] || categoryPreConfig[optionName] || preConfig[optionName] || defaultValue;
        });
        return customDimensions;
    };
    EnhanceEcommerce.trackGA4ViewItemList = function (itemListId, itemListName, productsJsonList) {
        var ga4Items = productsJsonList
            .filter(function (productJSON) { return !productJSON.contentInsert; })
            .map(function (productJSON, index) {
            return EnhanceEcommerce.buildGA4Item(_.omit(productJSON, 'concreteProducts'), index + 1, itemListName, itemListId, { categoryName: productJSON.category });
        });
        GA4EcommerceTracker_1.GA4EcommerceTracker.trackViewItemList(itemListId, itemListName, ga4Items);
    };
    EnhanceEcommerce.pullBasketItems = function () {
        return fetch('/basket/raw', {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(function (response) {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Network response was not ok');
        })
            .then(function (basketItems) {
            if (basketItems && Array.isArray(basketItems)) {
                var items_1 = [];
                basketItems.forEach(function (productJSON, index) {
                    var customOptions = {};
                    if ('concreteProducts' in productJSON &&
                        Array.isArray(productJSON.concreteProducts) &&
                        productJSON.concreteProducts.length > 0 &&
                        'format' in productJSON.concreteProducts[0]) {
                        customOptions['format'] = productJSON.concreteProducts[0].format;
                    }
                    if ('preConfig' in productJSON) {
                        customOptions = __assign(__assign({}, customOptions), productJSON.preConfig);
                    }
                    items_1.push(EnhanceEcommerce.buildGA4Item(productJSON, index, 'Order', 'order', customOptions));
                });
                return items_1;
            }
            throw new Error('dataset was not o.k.');
        });
    };
    return EnhanceEcommerce;
}());
exports.EnhanceEcommerce = EnhanceEcommerce;
