export const cssClass = {
	/**
	 * Appends multiple css classes to element
	 * @param {object} element
	 * @param {array} classList
	 */
	add(element, classList) {
		if (element && Array.isArray(classList)) {
			classList.forEach((className) => {
				element.classList.add(className);
			});
		}
	},

	/**
	 * Removes multiple css classes from element
	 * @param {object} element
	 * @param {array} classList
	 */
	remove(element, classList) {
		if (element && Array.isArray(classList)) {
			classList.forEach((className) => {
				element.classList.remove(className);
			});
		}
	},

	/**
	 * Replaces a css class with a new definition
	 * @param {object} element
	 * @param {string} oldClass
	 * @param {string} newClass
	 */
	replace(element, oldClass, newClass) {
		if (oldClass && element && element.classList.contains(oldClass)) {
			element.classList.remove(oldClass);
			element.classList.add(newClass);
		}
	},
};

export function getCookie(cookies, cookieToSearch) {
	const query = `${cookieToSearch}=`;
	const decodedCookies = decodeURIComponent(cookies);
	const cookiesArray = decodedCookies.split(';');

	for (let i = 0; i < cookiesArray.length; i++) {
		while (cookiesArray[i].charAt(0) === ' ') {
			cookiesArray[i] = cookiesArray[i].substring(1);
		}
		if (cookiesArray[i].indexOf(query) === 0) {
			return cookiesArray[i].substring(query.length, cookiesArray[i].length);
		}
	}

	return '';
}

/**
 * Use it to get some element from the DOM that is suppose to exist,
 * if does not exist throws an error
 *
 * @param element Element: DOM Element to be searched
 * @param query string: String with query for method querySelector
 * @returns {HTMLElement}
 */
export function getHTMLElement(element, query) {
	const elementSelected = element.querySelector(query);

	if (!elementSelected) {
		console.error(`Element with selector ${query} not found`);
		throw new Error(`Element with selector ${query} not found`);
	}

	return elementSelected;
}

/**
 * Create a single HTML Element out of a string.
 *
 * @param html string: HTML representing a single element
 * @returns {HTMLElement}
 */
export function createHTMLElement(html) {
	const template = document.createElement('template');

	template.innerHTML = html.trim();

	// fix for IE9+
	if (!('content' in template)) {
		const fragment = document.createDocumentFragment();

		fragment.appendChild(template.children[0]);

		return fragment.firstChild;
	}

	return template.content.firstChild;
}

/**
 * Use it to get some elements from the DOM that are suppose to exist (at least 1),
 * if does not exist throws an error
 *
 * @param element Element: DOM Element to be searched
 * @param query string: String with query for method querySelector
 * @returns {NodeListOf<HTMLElement>}
 */
export function getHTMLElements(element, query) {
	const elementsSelected = element.querySelectorAll(query);

	if (!elementsSelected.length) {
		console.error(`Elements with selector ${query} not found`);
		throw new Error(`Elements with selector ${query} not found`);
	}

	return elementsSelected;
}

/**
 * Use it to get the first child element of the given element.
 * If it does not exist throws an error
 *
 * @param element Element: DOM Element to be searched
 * @returns {HTMLElement}
 */
export function getFirstChildElement(element) {
	if (!element.firstElementChild) {
		console.error('No child element found');
		throw new Error('No child element found');
	}

	return element.firstElementChild;
}

/**
 * Use it to get the element with the given Id.
 * If it does not exist throws an error
 *
 * @param id string
 * @returns {HTMLElement}
 */
export function getElementById(id) {
	const element = document.getElementById(id);

	if (element === null) {
		console.error(`No element found with id ${id}`);
		throw new Error(`No element found with id ${id}`);
	}

	return element;
}

/**
 * Use it to get closest parent element which matches selector.
 * If it does not exist throws an error
 *
 * @param targetElement Element: DOM Element to start search from
 * @param selector string
 * @returns {HTMLElement}
 */
export function getClosestParentElement(targetElement, selector) {
	// return closest parent if browser supports Element.prototype.closest
	if (Element.prototype.closest) {
		return targetElement.closest(selector);
	}

	// if browser doesn't support Element.prototype.matches overwrite it with prefixed analog
	if (!Element.prototype.matches) {
		Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
	}

	const parent = targetElement.parentElement || targetElement.parentNode;

	if (parent === null || parent.nodeType !== Node.ELEMENT_NODE) {
		console.error(`No parent elements matched to selector '${selector}' found`);
		throw new Error(`No parent elements matched to selector '${selector}' found`);
	}

	if (parent.matches(selector)) {
		return parent;
	}

	return getClosestParentElement(parent, selector);
}

/**
 * Use it for the reloading the page
 */
export function reloadPage() {
	location.reload();
}

/**
 * Use it to navigate to another page
 *
 * @param url string: Url to navigate to
 * @param openInNewWindow boolean: Boolean to open the url in a new window or not
 */
export function navigate(url, openInNewWindow = false) {
	if (!openInNewWindow || !window.open(url, '_blank')) {
		location.href = url;
	}
}

/**
 * Checks if the image has been loaded from cache,
 * or attaches a 'load' event
 * @param imgElement
 * @returns {Promise<any>}
 */
export function waitForImageLoad(imgElement) {
	return new Promise((resolve, reject) => {
		try {
			if (imgElement.src && imgElement.complete) {
				resolve();
			} else {
				imgElement.addEventListener('load', () => {
					resolve();
				});
			}
		} catch (error) {
			reject(error);
		}
	});
}

/**
 * Checks if the element is in the viewport
 * @param el: HTMLElement
 * @return boolean
 */
export function isTotallyInViewport(el) {
	const bounding = el.getBoundingClientRect();

	return (
		bounding.top >= 0 &&
		bounding.left >= 0 &&
		bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}

/**
 * Checks if some part the element is in the viewport
 * @param el: HTMLElement
 * @return boolean
 */
export function isPartiallyInViewport(el, GAP = 0) {
	const rect = el.getBoundingClientRect();
	const windowHeight = window.innerHeight || document.documentElement.clientHeight;
	const windowWidth = window.innerWidth || document.documentElement.clientWidth;
	const vertInView = rect.top - GAP <= windowHeight && rect.top + rect.height >= 0;
	const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

	return vertInView && horInView;
}

export function scrollPageTo(scrollTop, duration) {
	jQuery('html, body').animate({ scrollTop }, duration);
}

/**
 *
 * @param attribute: string
 * @param startElement: HTMLElement
 * @param untilElement: HTMLElement
 * @returns string | undefined
 */
export function getAttributeFromTargetBubblingUpTo(attribute, startElement, untilElement) {
	for (let target = startElement; target && target !== untilElement; target = target.parentNode) {
		if (target.hasAttribute(attribute)) {
			return target.getAttribute(attribute);
		}
	}
}

export function getTotalWidthOfElementInPixels(element) {
	const style = window.getComputedStyle(element);
	const width = element.offsetWidth;
	const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);

	return width + margin;
}

export function getPositionRelativeToDocument(element) {
	const rect = element.getBoundingClientRect();
	const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
	const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

	return {
		top: rect.top + scrollTop,
		left: rect.left + scrollLeft,
	};
}
