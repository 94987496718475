import { getHTMLElement, getHTMLElements, reloadPage } from '../helpers/dom-interact';
import PageTypes from '../helpers/page-types';
import { WishlistCounter } from './WishList';

class UpdateUserAccount {
	constructor(element, pageType) {
		this.pageType = pageType;
		this.element = element;
		this.wishlistCounter = new WishlistCounter();
		this.cartCounterElement = getHTMLElement(element, '.js-header-cart-total');
		this.customerNameElements = getHTMLElements(element, '.js-customer-name');
		this.loginFlyoutPopupElements = getHTMLElements(element, '.js-login-flyout-popup');
		this.accountLoginPopupElement = getHTMLElement(element, '.js-account-login');
		this.accountMenuElements = getHTMLElements(element, '.js-account-menu');
		this.accountDropdownMenuElement = getHTMLElement(element, '.js-account-dropdown-menu');
		window.updateUserAccount = (data, action) => {
			this.init(data, action);
		};
	}

	init(data, action) {
		if (data.wishlistCount) {
			this.wishlistCounter.update(data.wishlistCount);

			if (typeof loadDataFoldout !== 'undefined') {
				loadDataFoldout(jQuery('#wishlist_overlay'));
			}
		}

		if (data.cartCount) {
			this.cartCounterElement.innerHTML = data.cartCount;
		}

		if (data.greetings) {
			_.each(this.customerNameElements, (item) => (item.innerHTML = data.greetings));
		}

		if (data.code === 'ok') {
			_.each(this.accountMenuElements, (item) => item.classList.remove('is-not-logged-in'));
			_.each(this.loginFlyoutPopupElements, (item) => item.classList.add('is-logged-in'));

			if (action) {
				this.showNotification(action);
			}
		}
	}

	isWishlistOrBasketPage() {
		const { pageType } = this;

		return pageType === PageTypes.BASKET || pageType === PageTypes.WISHLIST;
	}

	preventInteractionWithPage() {
		window.scrollTo(0, 0);
		document.body.classList.add('is-preventing-interaction');
	}

	showNotification(action) {
		const notification = getHTMLElement(this.element, `.popup-notification.${action}`);
		const accountLoginPopup = this.accountLoginPopupElement;
		const accountDropdownMenu = this.accountDropdownMenuElement;

		if (this.isWishlistOrBasketPage()) {
			this.preventInteractionWithPage();
		}

		accountLoginPopup.classList.add('is-hidden');

		notification.classList.remove('is-fade-out');

		setTimeout(() => {
			notification.classList.add('is-fade-out');
			setTimeout(() => {
				accountDropdownMenu.classList.add('is-visible');
				setTimeout(() => {
					accountDropdownMenu.classList.remove('is-visible');
					if (this.isWishlistOrBasketPage()) {
						reloadPage();
					}
				}, 2000);
			}, 500);
		}, 1500);
	}
}

export default UpdateUserAccount;
