"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WishlistCounter = void 0;
var WishlistCounter = /** @class */ (function () {
    function WishlistCounter() {
        // Using a list because we have separate layout elements for mobile and desktop
        this.counters = document.querySelectorAll('.js-header-wishlist-total');
    }
    WishlistCounter.prototype.update = function (count) {
        if (this.counters) {
            // innerHTML expects a string
            this.counters.forEach(function (counter) { return (counter.innerHTML = "".concat(count)); });
        }
    };
    return WishlistCounter;
}());
exports.WishlistCounter = WishlistCounter;
