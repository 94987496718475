"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixPagePosition = void 0;
var FixPagePosition = /** @class */ (function () {
    function FixPagePosition() {
        this.registeredComponents = [];
        if (!FixPagePosition.instance) {
            FixPagePosition.instance = this;
        }
    }
    FixPagePosition.getInstance = function () {
        return FixPagePosition.instance;
    };
    FixPagePosition.prototype.registerComponent = function (component) {
        var position = -window.scrollY;
        this.registeredComponents.push({ component: component, position: position });
        document.body.style.top = position + "px";
        document.body.classList.add(FixPagePosition.bodyClass);
    };
    FixPagePosition.prototype.unregisterComponent = function (component) {
        var registeredIndex = this.registeredComponents.findIndex(function (i) { return i.component === component; });
        if (registeredIndex > -1) {
            var position = this.registeredComponents[registeredIndex].position;
            this.registeredComponents.splice(registeredIndex, 1);
            if (!this.registeredComponents.length) {
                document.body.classList.remove(FixPagePosition.bodyClass);
                document.body.style.top = '';
                window.scrollTo(0, Math.abs(position));
            }
        }
    };
    FixPagePosition.instance = new FixPagePosition();
    FixPagePosition.bodyClass = 'is-overlay-open';
    return FixPagePosition;
}());
exports.FixPagePosition = FixPagePosition;
