import core from '../core';
import render from '../../helpers/render-template';
import observer from '../../helpers/events';
import { GA4UserEventsTracker } from '../GA4/GA4UserEventsTracker';

const popup = core.extend({
	constructor(options) {
		this.config = {
			popup: '.modal-window',
			content: '.modal-content',
			overlay: '.modal-window-overlay',
			closeBtn: '.modal-window .close-btn, .modal-window .js-close-btn',
			pagePosition: 0,
			isPopupOpened: false,
		};

		jQuery.extend(true, this.config, options || {});
	},

	init() {
		observer(this);

		this.template =
			'<div class="modal-window-overlay"><span class="spinner light"></span></div><div class="modal-window"><a href="#" class="close-btn without-text with-icon svg-close-window"></a><div class="modal-content"></div></div>';

		if (!jQuery(this.config.popup).length) {
			const popupHTML = render(this.template);

			jQuery('body').append(popupHTML);
		}

		this.popup = jQuery(this.config.popup);

		this.content = jQuery(this.config.content);

		this.overlay = jQuery(this.config.overlay);

		return this;
	},

	open(data) {
		if (this.config.className) {
			// add custom classname
			this.popup.addClass(this.config.className);
		} else {
			// remove previous custom classname
			this.popup.attr('class', this.config.popup.substr(1, this.config.popup.length));
		}
		this.config.pagePosition = jQuery(document).scrollTop();
		this.content.html(data);
		jQuery('body').css('top', -this.config.pagePosition).addClass('modal-opened');
		this.config.isPopupOpened = true;
		this.attachCloseEvent();

		this.attachTrackingEvents();

		this.publish('popup:opened');
	},

	close(isSuccess) {
		jQuery('body').removeClass('modal-opened').css('top', 0);
		jQuery(document).scrollTop(this.config.pagePosition);
		this.config.pagePosition = 0;
		this.publish('popup:closed', isSuccess);
		this.config.isPopupOpened = false;
	},

	remove(isSuccess) {
		this.popup.remove();
		this.overlay.remove();

		this.publish('popup:closed', isSuccess);
	},

	attachCloseEvent() {
		jQuery(`${this.config.closeBtn},${this.config.overlay}`)
			.off('click')
			.on('click', (e) => {
				e.preventDefault();

				const isSuccess = !(
					this.content.find('#login-ok').is(':hidden') && this.content.find('#register-ok').is(':hidden')
				);

				this[this.config.remove ? 'remove' : 'close'](isSuccess);
			});
	},

	attachTrackingEvents() {
		const registrationButton = this.content.find('input[name="registerForm[submit]"]');
		const loginButton = this.content.find('input[name="loginForm[submit]"]');

		if (registrationButton.length) {
			registrationButton.off('click').on('click', () => {
				GA4UserEventsTracker.trackUserRegistration(this.config.trackingCategory);
			});
		}

		if (loginButton.length) {
			loginButton.off('click').on('click', () => {
				GA4UserEventsTracker.trackUserLogin(this.config.trackingCategory);
			});
		}
	},
});

export default popup;
