const Class = function (options) {
	this.config = {};
	jQuery.extend(true, this.config, options || {});
};

const classMethods = {
	extend(protoProps, staticProps) {
		const parent = this;

		let child;

		if (protoProps && Object.prototype.hasOwnProperty.call(protoProps, 'constructor')) {
			child = protoProps.constructor;
		} else {
			child = function (...args) {
				return parent.apply(this, args);
			};
		}

		jQuery.extend(true, child, parent, staticProps);

		const Surrogate = function () {
			this.constructor = child;
		};

		Surrogate.prototype = parent.prototype;
		child.prototype = new Surrogate();

		if (protoProps) {
			jQuery.extend(true, child.prototype, protoProps);
		}

		child.__super__ = parent.prototype;

		return child;
	},

	include(...args) {
		args = args.length > 0 ? jQuery.makeArray(args) : [];

		args.unshift(this.prototype);
		jQuery.extend.apply(null, args);

		return this;
	},
};

const instanceMethods = {
	loadObjects(parent, selectors, target) {
		selectors = selectors || this.config.selectors;
		target = target || this.$;

		for (const key in selectors) {
			if (Object.prototype.hasOwnProperty.call(selectors, key)) {
				target[key] =
					typeof parent === 'string' ? jQuery(`${parent} ${selectors[key]}`) : jQuery(selectors[key], parent);
			}
		}

		return this;
	},

	jsonToQuery(json) {
		const hash = [];

		let val;

		for (const key in json) {
			if (Object.prototype.hasOwnProperty.call(json, key)) {
				val = json[key];

				if ((typeof val === 'string' || typeof val === 'number') && val !== '') {
					hash.push(`${key}=${val}`);
				}
			}
		}

		return hash.join('&');
	},
};

jQuery.extend(true, Class, classMethods);
Class.include(instanceMethods);

export default Class;
