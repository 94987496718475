const observer = function (EventEmitter) {
	const obj = jQuery({});

	jQuery.each(
		{
			on: 'subscribe',
			one: 'once',
			off: 'unsubscribe',
			trigger: 'publish',
		},
		(key, val) => {
			EventEmitter[val] = function () {
				obj[key](...arguments);

				return EventEmitter;
			};
		}
	);

	return EventEmitter;
};

export default observer;
