"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceManager = exports.WIDE_DESKTOP = exports.DESKTOP = exports.TABLET = exports.MOBILE = void 0;
var CustomEventTarget_1 = require("../helpers/CustomEventTarget");
exports.MOBILE = 1;
exports.TABLET = 2;
exports.DESKTOP = 3;
exports.WIDE_DESKTOP = 4;
var DeviceManager = /** @class */ (function (_super) {
    __extends(DeviceManager, _super);
    function DeviceManager() {
        var _this = _super.call(this) || this;
        _this._currentState = exports.WIDE_DESKTOP;
        _this.deviceManagerElement = DeviceManager.createDeviceManager();
        _this.updateState();
        _this.attachEvents();
        return _this;
    }
    DeviceManager.isIE = function () {
        var ua = navigator.userAgent;
        var ie = 0;
        if (ua.indexOf('MSIE ') > 0) {
            // <= 10
            ie = parseInt(ua.substring(ua.indexOf('MSIE ') + 5, ua.indexOf('.', ua.indexOf('MSIE '))), 10);
        }
        else if (ua.indexOf('Trident/') > 0) {
            // >= 11
            ie = parseInt(ua.substring(ua.indexOf('rv:') + 3, ua.indexOf('.', ua.indexOf('rv:'))), 10);
        }
        else if (ua.indexOf('Edge/') > 0) {
            // Edge >= 12
            ie = parseInt(ua.substring(ua.indexOf('Edge') + 5, ua.indexOf('Edge') + 7), 10);
        }
        return ie;
    };
    // detecting IE which is less than a version 10
    DeviceManager.detectIE = function () {
        return (~navigator.appName.indexOf('Internet Explorer') /* IE */ &&
            !~navigator.appVersion.indexOf('MSIE 1') /* not 10,11,12... */);
    };
    DeviceManager.isIOS = function () {
        return Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i));
    };
    DeviceManager.isAndroid = function () {
        return Boolean((navigator.userAgent || navigator.vendor).match(/android/i));
    };
    DeviceManager.isTouchDevice = function () {
        return 'ontouchstart' in document.documentElement;
    };
    DeviceManager.hasOrientation = function () {
        return typeof window.orientation !== 'undefined';
    };
    DeviceManager.createDeviceManager = function () {
        var existingDeviceManager = document.querySelector('.device-manager');
        if (existingDeviceManager) {
            return existingDeviceManager;
        }
        var el = document.createElement('div');
        el.classList.add('device-manager');
        document.body.appendChild(el);
        return el;
    };
    Object.defineProperty(DeviceManager.prototype, "state", {
        get: function () {
            return this.deviceManagerElement.offsetWidth;
        },
        enumerable: false,
        configurable: true
    });
    DeviceManager.prototype.getCurrentState = function () {
        return this._currentState;
    };
    DeviceManager.prototype.updateState = function () {
        var state = this.state;
        if (this._currentState !== state) {
            this._currentState = state;
            this.dispatchEvent(new CustomEvent('device:width:changed'), state);
        }
    };
    DeviceManager.prototype.isMobileDevice = function () {
        return this.getCurrentState() <= exports.TABLET;
    };
    DeviceManager.matches = function (selector) {
        return window.matchMedia(selector).matches;
    };
    DeviceManager.prototype.attachEvents = function () {
        var _this = this;
        window.addEventListener('resize', function () { return _this.updateState(); });
    };
    return DeviceManager;
}(CustomEventTarget_1.CustomEventTarget));
exports.DeviceManager = DeviceManager;
