import core from './core';
import CookieWarning from './cookie-warning';
import OpenClose from './open-close';
import MimicLink from './mixins/mimic-link';
import Tracker from '../helpers/tracker';
import { getEnvironment } from '../helpers/EnvironmentLocator';
import { SentryLogger } from '../helpers/Sentry';
import { DeviceManager } from './DeviceManager';
import { GA4BannerTracker } from './GA4/GA4BannerTracker';

const Common = core.extend({
	constructor(options) {
		if (!options) {
			throw new Error('parameter is missing');
		}

		const environment = getEnvironment(window.location);

		SentryLogger.getInstance().initializeSentry(environment);

		this.attachEvents(options);

		this.bindMimicLinks(options.trackingCategory);

		new OpenClose({
			parent: '.col',
			toggler: '.opener',
			useCSS: 'slide-toggler',
			content: '.list0',
			classOpen: 'open',
			disabledOnDesktop: true,
		});

		new CookieWarning({
			name: 'cookieAcceptance',
			daysToLive: 30,
		});

		if (DeviceManager.hasOrientation()) {
			document.body.classList.add('is-touch');
		}

		// polyfill for IE9+
		if (!Element.prototype.matches) {
			Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
		}
	},

	attachEvents(options) {
		const smoothScrollSelectors = [
			'a[href*="#"]:not([href="#"])',
			'span[data-href*="#"]:not([data-href="#"])',
			'article[data-href*="#"]:not([data-href="#"])',
		].join(',');

		/*
		 * smooth sliding for anchor links
		 * */
		jQuery(smoothScrollSelectors).on('click', function () {
			const $link = jQuery(this);

			let { pathname } = this;

			let { hostname } = this;

			let searchParams = this.search;

			let { hash } = this;

			let parser;

			if ($link.data('href')) {
				parser = document.createElement('a');
				parser.href = $link.data('href');

				pathname = parser.pathname;
				hostname = parser.hostname;
				searchParams = parser.search;
				hash = parser.hash;
			}

			if (
				location.pathname.replace(/^\//, '') === pathname.replace(/^\//, '') &&
				location.hostname === hostname &&
				location.search === searchParams
			) {
				let target = jQuery(hash);

				target = target.length ? target : jQuery(`[data-container=${hash.slice(1)}]`);
				if (target.length) {
					jQuery('html, body').animate({ scrollTop: target.offset().top }, 500);

					if (options.trackingCategory && $link.hasClass('tracking-link')) {
						Tracker.track({
							category: options.trackingCategory,
							action: $link.data('tracking-action'),
							label: $link.data('href') || $link[0].href,
							value: $link.data('tracking-value')
								? parseInt($link.data('tracking-value'), 10)
								: undefined,
						});
						GA4BannerTracker.trackBannerClick({
							block_section: options.trackingCategory,
							block_index: $link.data('tracking-value')
								? parseInt($link.data('tracking-value'), 10)
								: null,
							content_name: $link.data('href') || $link[0].href,
							content_id: $link.data('tracking-action'),
						});
					}

					return false;
				}
			}
		});
	},
});

Common.include(MimicLink);

export default Common;
