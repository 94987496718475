import core from './core';
import { getHTMLElement } from '../helpers/dom-interact';
import render from '../helpers/render-template';
import Template from './category-templates/cookie-warning';

const CookieWarning = core.extend({
	constructor(options) {
		// set up our internal properties
		this.config = {
			name: options.name,
			daysToLive: options.daysToLive,
			mainContainer: '#js-cookie-warning',
			closeBtn: '.js-close-btn',
			closeAnimationClass: 'close-animation',
		};

		// initialise if requirements are met
		if (
			navigator.cookieEnabled &&
			!this.checkCookie(this.config.name, document.cookie) &&
			!document.querySelector('#OneTrustCookies')
		) {
			this.init();
		}
	},

	init() {
		const pageContainer = document.getElementById('main');

		if (pageContainer === null || typeof cookieLayer === 'undefined') {
			return;
		}

		const htmlTemplate = this.renderTemplate(cookieLayer.text, cookieLayer.close);

		pageContainer.insertBefore(htmlTemplate, pageContainer.firstChild);

		this.attachEvents();
		this.storeCookie();
	},

	/**
	 * Render html template for cookie-panel
	 * @param infoText info panel localized text
	 * @param closeText close button localized text
	 * @returns {Node} notification panel element
	 */
	renderTemplate(infoText, closeText) {
		const cookieWarningPanel = document.createElement('div');

		cookieWarningPanel.innerHTML = render(Template, {
			text: infoText,
			close: closeText,
		});

		return cookieWarningPanel.firstElementChild;
	},

	attachEvents() {
		const mainContainerElement = getHTMLElement(document, this.config.mainContainer);
		const closeButtonElement = getHTMLElement(mainContainerElement, this.config.closeBtn);

		closeButtonElement.addEventListener('click', () => this.onClose(mainContainerElement));
	},

	/**
	 * closing the cookie-warning panel, indicates acceptance of the cookies
	 */
	onClose(mainContainerElement) {
		mainContainerElement.classList.add(this.config.closeAnimationClass);
	},

	storeCookie() {
		const date = new Date();

		date.setTime(date.getTime() + this.config.daysToLive * 24 * 60 * 60 * 1000);
		document.cookie = `${this.config.name}=true; expires=${date.toUTCString()}`;
	},

	/**
	 * function to search cookies for the given key
	 * @param keyToMatch string cookie key
	 * @param cookies document cookies
	 * @returns {boolean} whether cookie was found or not
	 */
	checkCookie(keyToMatch, cookies) {
		const cookieAsArray = cookies.split(';');

		for (let i = 0; i < cookieAsArray.length; i++) {
			const key = cookieAsArray[i].trim().split('=')[0];

			if (key === keyToMatch) {
				return true;
			}
		}

		return false;
	},
});

export default CookieWarning;
