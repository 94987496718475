"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiEndpoints = void 0;
var ApiEndpoints = /** @class */ (function () {
    function ApiEndpoints() {
    }
    ApiEndpoints.WISHLIST_BASE_URL = '/ajaxwishlist/ajax';
    ApiEndpoints.WISHLIST = '/wishlist/';
    ApiEndpoints.ADD_TO_WISHLIST = "".concat(ApiEndpoints.WISHLIST_BASE_URL, "/add");
    ApiEndpoints.GET_LOGIN_DIALOG = "".concat(ApiEndpoints.WISHLIST_BASE_URL, "/getLoginDialog");
    ApiEndpoints.ADD_TO_BASKET = "".concat(ApiEndpoints.WISHLIST_BASE_URL, "/addToCart");
    ApiEndpoints.UPDATE_BASKET = "".concat(ApiEndpoints.WISHLIST_BASE_URL, "/updateCart");
    ApiEndpoints.UPDATE_ITEM = "".concat(ApiEndpoints.WISHLIST_BASE_URL, "/updateItem");
    ApiEndpoints.ACTIONS_AND_ITEM_INFO = 'jsconfiapi/ajax/getActionsAndItemInfo/';
    ApiEndpoints.REMOVE_FROM_BASKET = '/customcheckout/ajax/remove';
    ApiEndpoints.REMOVE_FROM_WISHLIST = "".concat(ApiEndpoints.WISHLIST_BASE_URL, "/remove");
    ApiEndpoints.DUPLICATE_IN_BASKET = '/catalogIgniti/ajax/copyCartItem';
    ApiEndpoints.DUPLICATE_IN_WISHLIST = "".concat(ApiEndpoints.WISHLIST_BASE_URL, "/copy");
    ApiEndpoints.SEARCH = '/search';
    ApiEndpoints.SEARCH_MORE = "".concat(ApiEndpoints.SEARCH, "/more");
    ApiEndpoints.SEARCH_PAGINATION = "".concat(ApiEndpoints.SEARCH, "/pagination");
    ApiEndpoints.SEARCH_PAGINATION_FILTERS = "".concat(ApiEndpoints.SEARCH, "/pagination/filters");
    ApiEndpoints.SEARCH_EXPAND = "".concat(ApiEndpoints.SEARCH, "/expand");
    ApiEndpoints.UPDATE_WISHLIST_ITEM = "".concat(ApiEndpoints.WISHLIST_BASE_URL, "/updateWishlistItem");
    ApiEndpoints.COUPON = '/checkout/cart/couponAjax';
    ApiEndpoints.TEASER_PRODUCTS = '/teaserProducts';
    ApiEndpoints.GET_WISHLIST_CROSSSELLINGS = '/wishlist/item/crossSelling';
    ApiEndpoints.GET_WISHLIST_JSON = '/wishlist/items/json';
    ApiEndpoints.CUSTOMER_REMARK = '/customer-remark/update/index';
    return ApiEndpoints;
}());
exports.ApiEndpoints = ApiEndpoints;
