"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4PDPTracker = void 0;
var GA4Tracker_1 = require("./GA4Tracker");
var GA4PDPTracker = /** @class */ (function () {
    function GA4PDPTracker() {
    }
    GA4PDPTracker.trackOption = function (option, value) {
        switch (option.toLowerCase()) {
            case 'paper':
                return GA4PDPTracker.trackMaterialOption(value);
            case 'color':
                return GA4PDPTracker.trackColourOption(value);
            case 'format':
                return GA4PDPTracker.trackFormatOption(value);
            case 'quantity':
                return GA4PDPTracker.trackAmountOption(parseInt(value));
            case 'woodenblock':
                return GA4PDPTracker.trackBindingOption(value);
            case 'refinement':
                return GA4PDPTracker.trackFoilOption(value);
            case 'cover':
                return GA4PDPTracker.trackCoverTypeOption(value);
            case 'frame':
                return GA4PDPTracker.trackFrameOption(value);
            case 'innerpages':
                return GA4PDPTracker.trackInnerPagesOption(value);
            case 'numberofpages':
                return GA4PDPTracker.trackPageNumberOption(value);
            case 'ribbon':
                return GA4PDPTracker.trackRibbonOption(value);
            case 'diecut':
                return GA4PDPTracker.trackDiecutOption(value);
            case 'tag':
                return GA4PDPTracker.trackTagOption(value);
            case 'outerpaper':
                return GA4PDPTracker.trackOuterpaperOption(value);
            case 'amountofphotos':
                return GA4PDPTracker.trackPhotoamountOption(value);
            case 'box':
                return GA4PDPTracker.trackBoxOption(value);
        }
    };
    GA4PDPTracker.trackDropdownOptionField = function (option) {
        switch (option.toLowerCase()) {
            case 'paper':
                return GA4PDPTracker.trackMaterialDropdown();
            case 'format':
                return GA4PDPTracker.trackFormatDropdown();
            case 'prices':
                return GA4PDPTracker.trackAmountDropdown();
            case 'woodenblock':
                return GA4PDPTracker.trackBindingDropdown();
            case 'refinement':
                return GA4PDPTracker.trackFoilDropdown();
            case 'cover':
                return GA4PDPTracker.trackCoverTypeDropdown();
            case 'frame':
                return GA4PDPTracker.trackFrameDropdown();
            case 'innerpages':
                return GA4PDPTracker.trackInnerPageDropdown();
            case 'numberofpages':
                return GA4PDPTracker.trackPageNumberDropdown();
            case 'ribbon':
                return GA4PDPTracker.trackRibbonDropdown();
            case 'diecut':
                return GA4PDPTracker.trackDiecutDropdown();
            case 'tag':
                return GA4PDPTracker.trackTagDropdown();
            case 'outerpaper':
                return GA4PDPTracker.trackOuterPaperDropdown();
            case 'amountofphotos':
                return GA4PDPTracker.trackPhotoamountDropdown();
            case 'box':
                return GA4PDPTracker.trackBoxDropdown();
        }
    };
    GA4PDPTracker.trackPdpSocialNetworkEvent = function (socialNetwork) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { social_network_option: socialNetwork });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_share',
            pdp_product_share: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackMaterialOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { material: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_material',
            pdp_product_material: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackMaterialDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_material_dropdown',
            pdp_product_material_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackColourOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { colour: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_colour',
            pdp_product_colour: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackFormatOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { format: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_format',
            pdp_product_format: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackFormatDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_format_dropdown',
            pdp_product_format_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackAmountOption = function (amount) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { amount: amount });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_amount',
            pdp_product_amount: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackAmountDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_amount_dropdown',
            pdp_product_amount_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackBindingOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { binding: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_binding',
            pdp_product_binding: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackBindingDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_binding_dropdown',
            pdp_product_binding_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackFoilOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { foil: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_foil',
            pdp_product_foil: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackFoilDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_foil_dropdown',
            pdp_product_foil_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackCoverTypeOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { cover_type: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_cover_type',
            pdp_product_cover_type: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackCoverTypeDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_cover_type_dropdown',
            pdp_product_cover_type_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackFrameOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { frame: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_frame',
            pdp_product_frame: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackFrameDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_frame_type_dropdown',
            pdp_product_frame_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackInnerPagesOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { inner_pages: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_for_inner_page',
            pdp_product_inner_page: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackInnerPageDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_inner_page_type_dropdown',
            pdp_product_inner_page_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackPageNumberOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { page_number: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_for_page_number',
            pdp_product_page_number: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackPageNumberDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_page_number_type_dropdown',
            pdp_product_page_number_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackRibbonOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { ribbon: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_for_ribbon',
            pdp_product_ribbon: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackRibbonDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_ribbon_type_dropdown',
            pdp_product_ribbon_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackDiecutOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { diecut: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_for_diecut',
            pdp_product_diecut: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackDiecutDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_diecut_type_dropdown',
            pdp_product_diecut_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackTagOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { tag: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_for_tag',
            pdp_product_tag: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackTagDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_tag_type_dropdown',
            pdp_product_tag_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackOuterpaperOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { outerpaper: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_for_outerpaper',
            pdp_product_outerpaper: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackOuterPaperDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_outerpaper_type_dropdown',
            pdp_product_outerpaper_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackPhotoamountOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { photoamount: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_for_photoamount',
            pdp_product_photoamount: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackPhotoamountDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_photoamount_type_dropdown',
            pdp_product_photoamount_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackBoxOption = function (identifier) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { box: identifier });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_for_box',
            pdp_product_box: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackBoxDropdown = function () {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_product_box_type_dropdown',
            pdp_product_box_dropdown: this.getProductBaseTrackingDataSet(),
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackRelatedProductArrow = function (direction) {
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_related_products_arrow',
            pdp_related_products_arrow: {
                arrow_direction: direction,
            },
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackReleatedProduct = function (sku) {
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), { selected_product: sku });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_related_products',
            pdp_related_products: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.trackPersonalizedProduct = function (data) {
        var productId = data.product_id || productJSON.abstractSku;
        var categoryKey = productId.slice(-2);
        var longSku = productId;
        ['template_color_id', 'template_format_id'].forEach(function (key) {
            if (key in data && typeof data[key] === 'string' && data[key].length > 0) {
                longSku += '-' + data[key];
            }
        });
        var eventBody = __assign(__assign({}, this.getProductBaseTrackingDataSet()), {
            selected_product: productId,
            custom_item_format: data.template_format || '',
            custom_item_long_sku: longSku,
            custom_item_category_key: categoryKey,
        });
        var eventOptions = {
            event: 'custom_event',
            event_name: 'pdp_personalize_product',
            pdp_personalize_product: eventBody,
        };
        GA4Tracker_1.GA4Tracker.track(eventOptions);
    };
    GA4PDPTracker.getProductBaseTrackingDataSet = function () {
        return {
            custom_item_name: (productJSON === null || productJSON === void 0 ? void 0 : productJSON.name) || '',
            custom_item_id: (productJSON === null || productJSON === void 0 ? void 0 : productJSON.abstractSku) || '',
            custom_item_category: (websiteConfig === null || websiteConfig === void 0 ? void 0 : websiteConfig.name) || '',
            custom_item_category_2: (productJSON === null || productJSON === void 0 ? void 0 : productJSON.groupName) || '',
            custom_item_category_3: (productJSON === null || productJSON === void 0 ? void 0 : productJSON.name) || '',
        };
    };
    return GA4PDPTracker;
}());
exports.GA4PDPTracker = GA4PDPTracker;
