export default {
	clickOut(className, callBack) {
		jQuery(document).on('click touchstart', (ev) => {
			const curTarget = ev.target || ev.srcElement;
			const parent = jQuery(curTarget).parents(className);

			if (typeof parent[0] === 'undefined' && !jQuery(curTarget).hasClass(className.substr(1))) {
				callBack.call(this);
			}
		});
	},
};
