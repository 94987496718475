"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4ErrorTracker = void 0;
var GA4Tracker_1 = require("./GA4Tracker");
var GA4ErrorTracker = /** @class */ (function () {
    function GA4ErrorTracker() {
    }
    GA4ErrorTracker.track404Error = function () {
        var errorEvent = {
            event: 'page_meta',
            page_path: "/404?page=" + encodeURIComponent(document.location.pathname + document.location.search) + "&from=" + encodeURIComponent(document.referrer),
        };
        GA4Tracker_1.GA4Tracker.track(errorEvent);
    };
    return GA4ErrorTracker;
}());
exports.GA4ErrorTracker = GA4ErrorTracker;
