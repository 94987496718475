"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.WishlistButton = void 0;
var Types_1 = require("../../Modules/Types");
var Glossary_1 = require("../../helpers/Glossary");
var CustomEventTarget_1 = require("../../helpers/CustomEventTarget");
var Sentry_1 = require("../../helpers/Sentry");
var WishlistButton = /** @class */ (function (_super) {
    __extends(WishlistButton, _super);
    function WishlistButton(opts) {
        var _this = _super.call(this) || this;
        _this.elementsList = [];
        _this.isActive = false;
        _this.productJson = opts.data;
        _this.data = _this.initData(opts.data);
        _this.buttonId = opts.buttonId;
        _this.state = _this.getStateFromData();
        var buttonsAmount = opts.elementsList.length;
        for (var i = 0; i < buttonsAmount; i++) {
            var element = opts.elementsList[i];
            _this.elementsList.push(element);
            element.addEventListener('click', function (e) { return _this.onButtonClick(e); });
        }
        return _this;
    }
    WishlistButton.prototype.getStateFromData = function () {
        return {
            product: this.data.template_design,
            format: this.data.template_format,
            box: this.data.template_box_id,
            color: this.data.template_color,
            diecut: this.data.template_diecut === Glossary_1.Glossary.NO_DIECUT_SKU ? undefined : this.data.template_diecut,
            lacquer: this.data.lacquer_sku === Glossary_1.Glossary.NO_REFINEMENT_SKU ? undefined : this.data.lacquer_sku,
            outerPaper: this.data.outer_paper,
            paper: this.data.template_paper,
            // we should not rely on ribbon in state if tag not selected
            ribbon: this.data.template_diecut === Glossary_1.Glossary.RIBBON_AND_TAG_DIECUT ? this.data.ribbon : undefined,
            woodenBlock: this.data.wooden_block,
            frame: this.data.frame,
            innerPages: this.data.innerPages,
        };
    };
    WishlistButton.prototype.updateStateFromData = function () {
        this.state = this.getStateFromData();
        this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.BUTTON_STATE_CHANGE), this.buttonId);
    };
    WishlistButton.prototype.updateVisualState = function (isActive) {
        var _this = this;
        this.isActive = isActive;
        this.elementsList.forEach(function (element) {
            var inActiveIcon = element.getAttribute('data-inactive-class') || '';
            var activeIcon = element.getAttribute('data-active-class') || '';
            if (!inActiveIcon || !activeIcon) {
                Sentry_1.SentryLogger.getInstance().sendInfoReport({
                    message: 'Missing class attribute',
                });
                return;
            }
            if (_this.isActive) {
                element.classList.remove(inActiveIcon);
                element.classList.add(activeIcon);
            }
            else {
                element.classList.remove(activeIcon);
                element.classList.add(inActiveIcon);
            }
        });
    };
    // prettier-ignore
    WishlistButton.prototype.initData = function (data) {
        var preConfig = data.preConfig;
        var productData = this.getProductFromData(data);
        var format = productData.format, prices = productData.prices, productOptions = productData.productOptions;
        return {
            template_design: data.abstractSku,
            template_format: format,
            quantity: data.quantity || prices[0].quantity,
            template_color: data.template_color || (productOptions.color || []).length ? productOptions.color[0].key : '',
            template_paper: data.template_paper || preConfig.paper || (productOptions.papers || []).length ? productOptions.papers[0].key : '',
            template_diecut: data.template_diecut,
            template_box_id: data.template_box_id || preConfig.box,
            lacquer_sku: data.lacquer_sku,
            wooden_block: data.wooden_block || preConfig.woodenBlock,
            frame: data.wooden_frame,
            outer_paper: data.outer_paper,
            amountOfPhotos: data.amountOfPhotos || preConfig.amountOfPhotos,
            ribbon: data.ribbon || preConfig.ribbon,
            numberOfPages: data.numberOfPages || preConfig.numberOfPages,
            innerPages: data.innerPages || preConfig.innerPages,
        };
    };
    WishlistButton.prototype.getProductFromData = function (data) {
        var concreteProducts = data.concreteProducts, preConfig = data.preConfig, product = data.product;
        return (product ||
            concreteProducts.find(function (concreteProduct) { return concreteProduct.format === preConfig.format; }) ||
            concreteProducts[0]);
    };
    /* note: following method complexity will be handled with MOMS-212 */
    WishlistButton.prototype.setActiveProduct = function (product, preselection) {
        if (preselection === void 0) { preselection = {}; }
        var format = product.format, productOptions = product.productOptions;
        this.data.template_format = format;
        if (!productOptions.diecuts || productOptions.diecuts.length === 0) {
            this.data.template_diecut = '';
        }
        this.data.lacquer_sku = productOptions.refinements ? productOptions.refinements[0].key : '';
        this.data.wooden_block = this.getPropertyOfProduct(productOptions.woodenBlocks, preselection.woodenBlock);
        this.data.template_paper = this.getPropertyOfProduct(productOptions.papers, preselection.paper);
        this.data.outer_paper = this.getPropertyOfProduct(productOptions.outerPapers, preselection.outerPaper);
        this.data.template_diecut = this.getPropertyOfProduct(productOptions.diecuts, preselection.diecut);
        this.data.ribbon = this.getPropertyOfProduct(productOptions.ribbons, preselection.ribbon);
        this.updateStateFromData();
    };
    WishlistButton.prototype.getPropertyOfProduct = function (productOptionValuesArray, preselectionOptionValue) {
        if (preselectionOptionValue === void 0) { preselectionOptionValue = ''; }
        // there aren't possible option values
        if (!productOptionValuesArray) {
            return '';
        }
        // if option preselection doesn't specified we return first possible option
        if (!preselectionOptionValue) {
            return productOptionValuesArray[0].key;
        }
        var productOptionValue = productOptionValuesArray.find(function (productOption) { return preselectionOptionValue === productOption.key; }) || productOptionValuesArray[0];
        return productOptionValue.key;
    };
    WishlistButton.prototype.updateDataFromProductJson = function (newData) {
        Object.assign(this.data, newData);
        this.updateStateFromData();
    };
    WishlistButton.prototype.onButtonClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.BUTTON_CLICK), {
            state: this.state,
            passThroughParameters: {
                amountOfPhotos: this.data.amountOfPhotos,
                quantity: this.data.quantity,
            },
            isActive: this.isActive,
            productJSON: this.productJson,
        });
    };
    return WishlistButton;
}(CustomEventTarget_1.CustomEventTarget));
exports.WishlistButton = WishlistButton;
