"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MasterFeedIdBuilder = void 0;
var ProductJsonParser_1 = require("./ProductJsonParser");
var MasterFeedIdBuilder = /** @class */ (function () {
    function MasterFeedIdBuilder(productJSON) {
        this.productJSON = productJSON;
        this.productJsonParser = new ProductJsonParser_1.ProductJsonParser(productJSON);
        this.mappingToProductOptionKeys = {
            abstractSku: '',
            format: '',
            color: 'color',
            paper: 'papers',
            diecut: 'diecuts',
            refinement: 'refinements',
            woodenBlock: 'woodenBlocks',
            tag: 'tags',
            frame: 'frames',
            box: 'box',
            ribbon: 'ribbons',
            innerPages: 'innerPages',
        };
    }
    MasterFeedIdBuilder.prototype.getMasterFeedId = function (customOptions) {
        var _this = this;
        var activeFormat = this.productJsonParser.getActiveFormat(customOptions);
        var idTokens = {
            abstractSku: this.productJSON.abstractSku,
            format: activeFormat,
            color: '',
            paper: '',
            diecut: '',
            refinement: '',
            woodenBlock: '',
            tag: '',
            frame: '',
            box: '',
            ribbon: '',
            innerPages: '',
        };
        Object.keys(idTokens).map(function (tokenName) {
            if (tokenName !== 'abstractSku' && tokenName !== 'format') {
                idTokens[tokenName] = _this.getOptionValue(tokenName, customOptions);
            }
        });
        if (idTokens.tag === 'T00') {
            idTokens.ribbon = '';
        }
        // Keep this list in sync with MasterFeedIdBuilder::ALLOWED_PAPERS
        var isPaperAllowed = ['P50'].includes(idTokens.paper);
        if (!isPaperAllowed) {
            idTokens.paper = '';
        }
        var values = Object.keys(idTokens).map(function (key) { return idTokens[key]; });
        // Keep this in sync with MasterFeedIdBuilder::build()
        var nullValues = ['D00', 'D50', 'V00', 'T00', 'WF00', 'DF00', 'B00'];
        return values.filter(function (e) { return e && !nullValues.includes(e); }).join('-');
    };
    MasterFeedIdBuilder.prototype.getOptionValue = function (optionName, customOptions) {
        var availableProductOptions = this.productJsonParser.getAvailableProductOptions(this.mappingToProductOptionKeys[optionName], customOptions);
        if (Object.prototype.hasOwnProperty.call(customOptions, optionName)) {
            if (this.productJsonParser.isOptionValueAvailable(availableProductOptions, customOptions[optionName])) {
                return customOptions[optionName];
            }
        }
        var preConfig = this.productJSON.preConfig;
        if (Object.prototype.hasOwnProperty.call(preConfig, optionName) && preConfig[optionName]) {
            if (this.productJsonParser.isOptionValueAvailable(availableProductOptions, String(preConfig[optionName]))) {
                return String(preConfig[optionName]);
            }
        }
        return this.getDefaultValue(customOptions, optionName);
    };
    MasterFeedIdBuilder.prototype.getDefaultValue = function (customOptions, optionName) {
        var productOptionKey = this.mappingToProductOptionKeys[optionName];
        return this.productJsonParser.getDefaultValueForProductOption(productOptionKey, customOptions);
    };
    return MasterFeedIdBuilder;
}());
exports.MasterFeedIdBuilder = MasterFeedIdBuilder;
