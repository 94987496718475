"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingData = void 0;
var TrackingData;
(function (TrackingData) {
    /* Types of page used for tracking on GA */
    TrackingData["PAGE_CP"] = "fury_cp";
    TrackingData["PAGE_PDP"] = "fury_pdp";
    TrackingData["PAGE_CROSSSELLING"] = "fury_crossselling";
    TrackingData["PAGE_BASKET"] = "fury_basket";
    TrackingData["PAGE_WISHLIST"] = "fury_wishlist";
    TrackingData["NAVIGATION_MENU"] = "fury_navi";
    TrackingData["PAGE_FURY_IN_MAGENTO"] = "magento";
    TrackingData["PAGE_FURY_HEADER"] = "fury_header";
    TrackingData["FURY_STATIC_PAGE"] = "fury_static_page";
    /* Types of action used for tracking on GA */
    TrackingData["ACTION_TEST_PRODUCT_OPEN_CONFIGURATOR"] = "test_product_open_configurator";
    TrackingData["ACTION_TEST_PRODUCT_ADD_TO_WISHLIST"] = "test_product_add_to_wishlist";
    TrackingData["ACTION_WISHLIST_SEEN"] = "wishlist_seen";
    TrackingData["ACTION_ADD_TO_BASKET"] = "add_to_basket";
    TrackingData["ACTION_ADD_TO_WISHLIST"] = "add_to_wishlist";
    TrackingData["ACTION_REMOVE_FROM_WISHLIST"] = "remove_from_wishlist";
    TrackingData["ACTION_CHANGE_PAGE"] = "change_page";
    TrackingData["ACTION_HEADLINES_1_BANNER_CLICK"] = "headlines_1_banner_click";
    TrackingData["ACTION_OPEN_ZOOM"] = "open_zoom";
    TrackingData["ACTION_SHOPPING_PDP_CLICK"] = "shopping_pdp_click";
    TrackingData["ACTION_SHOPPING_CP_CLICK"] = "shopping_cp_click";
    TrackingData["ACTION_NAVIGATION_CLICK"] = "navigation_click";
    TrackingData["ACTION_SUBNAVIGATION_CLICK"] = "item_click";
    TrackingData["ACTION_OPEN_NAVI"] = "open_navi";
    TrackingData["ACTION_TOP_INFO_BANNER"] = "top_info_banner";
    TrackingData["ACTION_CUSTOM_COLOR_CLICK"] = "custom_color_click";
    TrackingData["ACTION_CUSTOM_FORMAT_CLICK"] = "custom_format_click";
    TrackingData["ACTION_PRODUCT_SLIDER_1_SLIDE"] = "product_slider_1_slide";
    TrackingData["ACTION_SHARED_LINK"] = "shared_link";
    TrackingData["ACTION_OPEN_SHARE_PANEL"] = "open_share_panel";
    TrackingData["ACTION_FILTER_RESET_ALL"] = "filter_reset_all";
    TrackingData["ACTION_FILTER_OPEN_PANEL"] = "filter_open_panel";
    TrackingData["ACTION_FILTER_CLOSE_PANEL"] = "filter_close_panel";
    TrackingData["ACTION_FILTER_TAB_CLICK"] = "filter_tab_click";
    TrackingData["ACTION_FILTER_RESULTS_CLICK"] = "filter_results_click";
    TrackingData["ACTION_SLIDER_1_BANNER_SLIDE"] = "slider_1_banner_slide";
    TrackingData["ACTION_SAVE_MY_WISHLIST_CLICKED"] = "save_my_wishlist_clicked";
    TrackingData["ACTION_CROSSSELLING_CLICK_ON_TAB"] = "click_on_tab";
    TrackingData["ACTION_CROSSSELLING_TO_BASKET"] = "to_basket";
    TrackingData["ACTION_CROSSSELLING_SEE_PRODUCT"] = "see_product";
    TrackingData["ACTION_CROSSSELLING_CSBUTTON_TO_BASKET"] = "csbutton_to_basket";
    TrackingData["ACTION_CROSSSELLING_CSBUTTON_OPEN"] = "csbutton_open";
    TrackingData["ACTION_CROSSSELLING_CSBUTTON_EDIT"] = "csbutton_edit";
    TrackingData["ACTION_CROSSSELLING_CSBUTTON_DELETE"] = "csbutton_delete";
    TrackingData["ACTION_CROSSSELLING_CSBUTTON_CHANGE"] = "csbutton_change";
    TrackingData["ACTION_CROSSSELLING_CSBUTTON_OPEN_CONFIGURATOR"] = "open_configurator";
    TrackingData["ACTION_CROSSSELLING_DELETE_MESSAGE"] = "delete_message";
    TrackingData["ACTION_CROSSSELLING_CSBUTTON_SAVE_CHANGE"] = "csbutton_save_change";
    TrackingData["ACTION_DESIGNSERVICE_ADD_TO_BASKET"] = "b2bservice_popup_add_to_basket";
    TrackingData["ACTION_DESIGNSERVICE_GO_TO_SERVICE_LP"] = "b2bservice_popup_lplink_click";
    TrackingData["ACTION_DESIGNSERVICE_CLOSE"] = "b2bservice_popup_close_popup";
    TrackingData["ACTION_MORE_DETAILS_POP_UP"] = "more_details_popup";
    TrackingData["ACTION_TEASER_CLICK"] = "teaser_click";
    /* Kartenmacherei App Tracking types */
    TrackingData["ACTION_REAL_DOOR_POPUP_CLOSE"] = "app_fakedoor_close_clicked";
    TrackingData["ACTION_REAL_DOOR_DOWNLOAD_APP_CLICK"] = "app_fakedoor_download_app_clicked";
    TrackingData["ACTION_REAL_DOOR_GO_TO_CONFIGURATOR_CLICK"] = "app_fakedoor_goweb_clicked";
    TrackingData["ACTION_POSTER_REAL_DOOR_POPUP_CLOSE"] = "app_posters_fakedoor_close_clicked";
    TrackingData["ACTION_POSTER_REAL_DOOR_DOWNLOAD_APP_CLICK"] = "app_posters_fakedoor_download_app_clicked";
    TrackingData["ACTION_POSTER_REAL_DOOR_GO_TO_CONFIGURATOR_CLICK"] = "app_posters_fakedoor_goweb_clicked";
    /* Types of labels used for tracking on GA */
    TrackingData["LABEL_LOGGED_IN"] = "logged_in";
    TrackingData["LABEL_ANONYMOUS"] = "anonymous";
    TrackingData["LABEL_RESET_FILTER"] = "reset_filter";
    TrackingData["LABEL_LEFT"] = "left";
    TrackingData["LABEL_RIGHT"] = "right";
    TrackingData["LABEL_LIGHTBOX"] = "lightbox";
})(TrackingData = exports.TrackingData || (exports.TrackingData = {}));
