import core from './core';
import Popup from './popups/popup';
import TopInfoBanner from './top-info-banner';
import IEFixes from './ieFixes';
import ConfiButton from './confi-button';
import clickOut from './mixins/click-out';
import Tracker from '../helpers/tracker';
import { TrackingData } from '../helpers/TrackingTypes';
import { Xhr } from '../Modules/HttpRequest';
import { ApiEndpoints } from '../Configurations/ApiEndpoints';
import { DeviceManager } from './DeviceManager';
import { Glossary } from '../helpers/Glossary';
import { LoadingIndicator } from '../components/mixins/loading-indicator';
import { GA4HeaderMenuTracker } from './GA4/GA4HeaderMenuTracker';
import { GA4UserEventsTracker } from './GA4/GA4UserEventsTracker';
import { GA4NewsletterTracker } from './GA4/GA4NewsletterTracker';

/* global domainsXmlUrl, jessiUrl */

const HeaderFeatures = core.extend({
	constructor(options) {
		if (!options) {
			throw new Error('parameter is missing');
		}

		this.config = {
			body: 'body',
			classOpen: 'is-menu-open',
			opener: '.js-hamburger-menu',
			overlay: '.page-overlay',
			navDropdownOverlay: '.nav-dropdown-overlay',
			page: '.page',
			subMenu: '.sub-menu',
			foldoutLink: 'li.overlay_link>a',
			ajaxEndpoints: {
				wishlist: '/wishlist/flyout',
				basket: '/basket/flyout',
			},
			foldout: '.header-popup',
			popupOpener: '.popup-opener',
			mobileUserMenu: '#nav .header-popup, #nav-fury .header-popup',
			accountLink: 'li.account>a',
			activeClass: 'active',
			firstLevelNavEl: '.js-first-level-menu-item',
			secondLevelNavEl: '.js-second-level-menu-element',
			closeMenuBtn: '.js-close-menu',
			openDropdownClass: 'is-dropdown-open',
			mainMenuLink: '.js-first-level-menu-link',
			saveWishlistLink: '.js-save-wishlist',
			topInfoBlock: '.js-top-info-holder',
			headerReviewBlock: '.header-tools>.ekomi-block',
		};

		this.deviceManager = new DeviceManager();

		this.loadingIndicator = new LoadingIndicator();

		jQuery.extend(true, this.config, options || {});

		_.bindAll(this, 'renderLoginPopup');

		this.init();

		const topInfoBlock = jQuery(this.config.topInfoBlock);

		if (topInfoBlock.length && navigator.cookieEnabled) {
			new TopInfoBanner({
				el: topInfoBlock.first(),
				trackingCategory: this.config.trackingCategory,
			});
		}
	},

	init() {
		this.loggedIn = typeof customerGroup !== 'undefined' && customerGroup && customerGroup !== 'NOT LOGGED IN';
		this.loadingLoginForm = false;

		this.trackNewsletterSuccessPage();

		if (!this.loggedIn) {
			jQuery(this.config.accountLink).parent().addClass('is-not-logged-in');
		}

		this.attachEvents();

		if (!!DeviceManager.isIE() && DeviceManager.isIE() < 10) {
			new IEFixes({
				accountLink: 'li.account>a',
				loggedIn: this.loggedIn,
			});
		}

		jQuery(this.config.firstLevelNavEl).each((ind, el) => {
			el = jQuery(el);

			if (DeviceManager.hasOrientation()) {
				el.find('.mobile-menu-view').eq(0).addClass('show-all');
			} else {
				el.find(this.config.closeMenuBtn).css({ visibility: 'hidden' });
			}
		});
	},

	toggleMenu() {
		const body = jQuery(this.config.body);

		if (body.hasClass(this.config.classOpen)) {
			jQuery(this.config.mobileUserMenu).hide().find('.frame .login-form').empty();
		} else if (!body.find(this.config.overlay).length) {
			body.find(this.config.page).append(`<div class="${this.config.overlay.substring(1)}" />`);
		}

		body.toggleClass(this.config.classOpen);
	},

	showFoldout(e) {
		const currentEl = jQuery(e.currentTarget).parent();
		const foldout = currentEl.find(this.config.foldout);

		if (!foldout.hasClass('loading')) {
			foldout.addClass('loading');

			this.loadDataFoldout(foldout);

			window.loadDataFoldout = this.loadDataFoldout.bind(this);
		}
	},

	loadDataFoldout(foldout) {
		const contentFoldout = foldout.find('.head_overlay_content').first();
		const contentId = contentFoldout.attr('id');
		const blockName = contentFoldout.attr('rel');
		const holder = jQuery(`#${contentId}`);
		const list = jQuery('<ul></ul>');

		if (typeof blockName === 'undefined' || blockName === '') {
			return;
		}

		jQuery.ajax({
			url: this.config.ajaxEndpoints[blockName],
			type: 'GET',
			cache: false,
			success: function (data) {
				jQuery(`#${foldout.find('.head_overlay_loading').first().attr('id')}`).hide();

				data = jQuery(data.blocks.content);

				if (data.find('script').length) {
					// for CS/Cart/WL pages old JS is being used

					data.find('li').each((i, el) => {
						const scriptElement = jQuery(el).next();

						if (
							scriptElement.length &&
							scriptElement.prop('tagName').toLowerCase() === 'script' &&
							jQuery(el).find('.jsconfibutton').length
						) {
							const formatted = this.magentoFuryConnector(jQuery(el), scriptElement.html());

							list.append(formatted ? formatted.html : el);

							const confiButton = new ConfiButton({
								el: list.find('li').eq(i).find('.jsconfibutton'),
								data: formatted.data,
								mode: formatted.data.view,
							});

							// Todo: delete this when KUN-928 is done
							if (DeviceManager.detectIE()) {
								confiButton.buttons[0].el.hide();
							} else {
								confiButton.update();
							}
						} else {
							list.append(el);
						}
					});

					data.find('ul').html(list.find('li'));
				}

				holder.html(data);
			}.bind(this),
			dataType: 'json',
		});
	},

	/** ********************************************** */

	// method 'magentoFuryConnector' is connector between magento legacy-code and fury new code.
	// method has to be completely removed after release fury for all countries.

	/** ********************************************** */

	magentoFuryConnector(item, script) {
		let params;

		if (script.includes('var params')) {
			// basket flyout
			params = script.split('if (')[0].split(' = ')[1].replace(/'/g, '"');
		} else {
			// wishlist flyout
			params = item
				.find('.jsconfibutton')
				.attr('onclick')
				.split('; openJsConfigurator')[0]
				.split('var params = ')[1];
		}

		params = JSON.parse(params);

		params.paper = item.data('paper') || '';

		item.find('.jsconfibutton').removeAttr('onclick').removeAttr('style');

		const pass_through = params.pass_through || '';
		const tag_sku =
			params.diecutSku === Glossary.RIBBON_AND_TAG_DIECUT &&
			params.formatSku !== 'F600' &&
			params.formatSku !== 'F601' &&
			params.formatSku !== 'F602' &&
			params.tagSku
				? params.tagSku
				: '';

		// Workaround for Transparent Inserts
		if (
			params.productSku.length === 7 &&
			params.productSku.indexOf('F9') !== -1 &&
			params.productSku.indexOf('P05') !== -1
		) {
			params.colorSku = 'C00';
			params.formatSku = params.productSku.substr(0, 4);
			params.color = 0;
			params.format = 0;
			params.diecutId = 0;
			params.paper = 0;
			params.lacquerSku = '';
			params.diecutSku = '';
		}

		return {
			data: {
				concreteProducts: [
					{
						format: params.formatSku,
						jessi: !!item.data('jessi-available'),
						mobile: !!item.data('mobile-available'),
						productOptions: {},
					},
				],
				abstractSku: params.productSku,
				preConfig: { format: params.formatSku },
				template_format: params.formatSku,
				locale: params.locale,
				externalStoreCode: params.externalStoreCode,
				storeCode: params.storeCode,
				quantity: params.quantity,
				template_color: params.colorSku,
				template_color_id: params.colorSku,
				template_diecut: params.diecutSku,
				template_diecut_id: params.diecutSku,
				lacquer_sku: params.lacquerSku,
				tag_sku,
				pass_through: typeof pass_through === 'object' ? JSON.stringify(pass_through) : pass_through,
				quote_item_id: params.quote_item_id,
				view: params.view,
				configurationID: params.cID,
				template_paper: params.paper,
				template_paper_id: params.paper,
				amountOfPhotos: params.amountOfPhotos,
				template_amount_of_photos: params.template_amount_of_photos,
				template_amount_of_photos_id: params.template_amount_of_photos,
				template_box: params.template_box,
				template_box_id: params.template_box,
				ribbon: params.ribbon,
				numberOfPages: params.numberOfPages,
				domainsXmlUrl,
				jessiUrl,
				hash: item.data('hash'),
			},
			html: item,
		};
	},

	toggleUserMenu(e) {
		const el = jQuery(e.currentTarget);
		const targetPopup = el.parents('#nav, #nav-fury').find(`.${el.data('target')}`);
		const popups = jQuery(this.config.mobileUserMenu).not(`.${el.data('target')}`);

		popups.hide();
		targetPopup[targetPopup.is(':hidden') ? 'show' : 'hide']();
	},

	attachEvents() {
		const isTablet =
			window.innerWidth > 640 && window.innerWidth < 1025 && typeof window.orientation !== 'undefined';

		jQuery(document)
			.on('click', this.config.opener, (e) => {
				e.preventDefault();

				this.toggleMenu();

				Tracker.track({
					category: TrackingData.NAVIGATION_MENU,
					action: TrackingData.ACTION_OPEN_NAVI,
				});
			})
			.on('click', this.config.overlay, () => this.toggleMenu())
			.on('click', this.config.subMenu, (e) => {
				if (jQuery(window).width() < 1001) {
					e.preventDefault();
					e.stopPropagation();

					jQuery(e.currentTarget).toggleClass('open');
				}
			})
			// foldouts for wishlist and cart
			.on('mouseenter', this.config.foldoutLink, (e) => this.showFoldout(e))
			.on('click', this.config.foldoutLink, (e) => {
				if (window.innerWidth > 640 && window.innerWidth < 1025 && typeof window.orientation !== 'undefined') {
					// this condition should be replaced with "isTablet" when we complitely get rid of magento pages

					e.preventDefault();
					e.stopPropagation();

					const el = jQuery(e.currentTarget).parent();

					if (!el.hasClass(this.config.activeClass)) {
						this.showFoldout(e);
					}

					jQuery(this.config.foldoutLink).parent().not(el).removeClass(this.config.activeClass);
					el.toggleClass(this.config.activeClass);
				}
			})
			.on('click', this.config.popupOpener, (e) => {
				this.toggleUserMenu(e);
			})
			.on('click', this.config.closeMenuBtn, (e) => {
				e.stopPropagation();
				e.preventDefault();

				jQuery(e.currentTarget)
					.parents('li')
					.find(this.config.subMenu)
					.removeClass(this.config.openDropdownClass);
			});

		jQuery(this.config.accountLink).on('click', (e) => {
			const el = jQuery(e.currentTarget);
			const mobile = !el.parents('.header-tools').length;
			const popup = el.parents('#nav, #nav-fury').find('.js-login-flyout-popup');

			this.loggedIn = !el.parent().hasClass('is-not-logged-in');

			if (mobile || !this.loggedIn) {
				e.preventDefault();

				if (mobile && this.loggedIn) {
					popup[popup.is(':hidden') ? 'addClass' : 'removeClass']('is-logged-in');
				} else if (!mobile && !this.loggedIn) {
					this.loadLoginForm(e, this.renderLoginPopup);
				} else if (popup.is(':hidden')) {
					this.loadLoginForm(e, jQuery.proxy(this.renderLoginFlyout, this));
				} else {
					popup.find('.frame .login-form').empty();
				}
			}
		});

		//Show hide overlay when user menu icons are hovered
		const iconsWithOverlayOnHover = [this.config.headerReviewBlock, this.config.firstLevelNavEl].join(', ');

		const iconsWithOverlayOnParentHover = [this.config.accountLink, this.config.foldoutLink].join(', ');

		jQuery(iconsWithOverlayOnHover).on('mouseenter', () => {
			jQuery(this.config.navDropdownOverlay).addClass(this.config.openDropdownClass);
		});

		jQuery(iconsWithOverlayOnHover).on('mouseleave', () => {
			jQuery(this.config.navDropdownOverlay).removeClass(this.config.openDropdownClass);
		});

		jQuery(iconsWithOverlayOnParentHover)
			.parent()
			.on('mouseenter', () => {
				jQuery(this.config.navDropdownOverlay).addClass(this.config.openDropdownClass);
			});

		jQuery(iconsWithOverlayOnParentHover)
			.parent()
			.on('mouseleave', () => {
				jQuery(this.config.navDropdownOverlay).removeClass(this.config.openDropdownClass);
			});

		jQuery(this.config.accountLink)
			.parent()
			.on('mouseenter mouseleave', (e) => {
				const el = jQuery(e.currentTarget);
				const flyout = el.find('.header-popup.account-login');

				if (flyout.find('#login-error').css('display') === 'block') {
					// error msg is shown

					flyout.addClass('active');

					jQuery(document).on('click', () => {
						// clickout

						jQuery(document).off('click');

						flyout.removeClass('active');
					});
				}

				if (flyout.hasClass('active')) {
					return;
				}

				this.loggedIn = !el.hasClass('is-not-logged-in');

				if (e.type === 'mouseenter') {
					if (el.parents('.header-tools').length && !this.loggedIn) {
						this.loadLoginForm(e, jQuery.proxy(this.renderLoginFlyout, this));
					}
				}
			});

		jQuery(this.config.navDropdownOverlay).on('mouseenter', () => {
			jQuery(this.config.firstLevelNavEl).removeClass(this.config.openDropdownClass);
		});

		jQuery(this.config.firstLevelNavEl)
			.on('mouseenter', (e) => {
				if (!DeviceManager.hasOrientation() && jQuery(window).width() > 1000) {
					jQuery(e.currentTarget).find(`>${this.config.subMenu}`).addClass(this.config.openDropdownClass);

					this.calculatePosition(e);
				}
			})
			.on('mouseleave', (e) => {
				if (!DeviceManager.hasOrientation() && jQuery(window).width() > 1000) {
					jQuery(e.currentTarget).find(`>${this.config.subMenu}`).removeClass(this.config.openDropdownClass);
				}
			})
			.on('click', (e) => {
				const link = jQuery(e.currentTarget).find(`>${this.config.subMenu}`);

				if (
					DeviceManager.hasOrientation() &&
					jQuery(window).width() > 1000 &&
					link.length &&
					!link.hasClass(this.config.openDropdownClass)
				) {
					e.preventDefault();

					link.addClass(this.config.openDropdownClass);

					this.calculatePosition(e);

					jQuery(this.config.firstLevelNavEl)
						.not(e.currentTarget)
						.find(`>${this.config.subMenu}`)
						.removeClass(this.config.openDropdownClass);
				}
			});
		jQuery(`${this.config.mainMenuLink}, ${this.config.secondLevelNavEl}`).on('click', (e) => {
			e.preventDefault();
			const currentTarget = jQuery(e.currentTarget);
			const navigateUrl = currentTarget.attr('href');
			// needs to be kept in sync with Frontend/static/styles/kartenmacherei-rebrush/common/_vars.scss:$viewHamburgerMenu
			const usesHamburgerMenu = DeviceManager.matches('screen and (max-width: 1000px)');
			const isSubMenuOpenItem = usesHamburgerMenu && currentTarget.hasClass('sub-menu');
			if (navigateUrl.length && !isSubMenuOpenItem) {
				const trackingAction = e.currentTarget.classList.contains('js-second-level-menu-element')
					? TrackingData.ACTION_SUBNAVIGATION_CLICK
					: TrackingData.ACTION_NAVIGATION_CLICK;

				this.trackNavigation(currentTarget, trackingAction);
			}
		});

		if (DeviceManager.hasOrientation()) {
			jQuery('.page').on('click', (e) => {
				jQuery(e.target).parents('.first-level-menu').length ||
					jQuery(this.config.firstLevelNavEl)
						.find(`>${this.config.subMenu}`)
						.removeClass(this.config.openDropdownClass);
			});
		}

		if (isTablet) {
			this.clickOut(this.config.foldout, function () {
				jQuery(this.config.foldoutLink).parent().removeClass(this.config.activeClass);
			});
		}

		jQuery(this.config.saveWishlistLink).on('click', (e) => {
			this.loadLoginForm(e, this.renderLoginPopup);

			Tracker.track({
				category: TrackingData.PAGE_WISHLIST,
				action: TrackingData.ACTION_SAVE_MY_WISHLIST_CLICKED,
				label: TrackingData.LABEL_ANONYMOUS,
			});
		});

		// tracking events
		jQuery('#header')
			.on('click', '.main-logo a', (event) => {
				this.handleLinkToTrackClick(event, 'click_kamlogo');
			})
			.on('click', '.js-header-rating-btn', (event) => {
				this.handleLinkToTrackClick(event, 'click_reviews');
			})
			.on('click', '.js-header-contact-btn', (event) => {
				if (!this.deviceManager.isMobileDevice()) {
					this.handleLinkToTrackClick(event, 'click_contactIcon');
				}
			})
			.on('click', '.js-contact-popup-contact-btn', (event) => {
				this.handleLinkToTrackClick(event, 'click_contact');
			})
			.on('click', '.js-contact-popup-faq-btn', (event) => {
				this.handleLinkToTrackClick(event, 'click_faq');
			})
			.on('click', '.js-popup-all-reviews-btn', (event) => {
				this.handleLinkToTrackClick(event, 'click_allReviews');
			})
			.on('click', '.js-header-wishlist-btn', (event) => {
				if (!this.deviceManager.isMobileDevice()) {
					this.handleLinkToTrackClick(event, 'click_wishlistIcon');
				}
			})
			.on('click', '.js-wishlist-popup-wishlist-btn', (event) => {
				this.handleLinkToTrackClick(event, 'click_goToWishlist');
			})
			.on('click', '.js-header-basket-btn', (event) => {
				if (!this.deviceManager.isMobileDevice()) {
					this.handleLinkToTrackClick(event, 'click_basketIcon');
				}
			})
			.on('click', '.js-basket-popup-basket-btn', (event) => {
				this.handleLinkToTrackClick(event, 'click_goToBasket');
			});
	},

	trackNavigation(currentTarget, trackingAction) {
		const navigateUrl = currentTarget.attr('href');
		const openInNewWindow = currentTarget.attr('target') === '_blank';

		const parentMenu = currentTarget.closest(this.config.firstLevelNavEl);
		const childMenu = currentTarget.closest(this.config.secondLevelNavEl);

		const parentMenuElement = parentMenu.length ? parentMenu[0] : null;
		const childMenuElement = childMenu.length ? childMenu[0] : null;

		const category_1 = parentMenuElement
			? (parentMenuElement.querySelector('a, span')?.textContent || '').trim()
			: '';
		const category_2 = childMenuElement
			? (childMenuElement.querySelector('a, span')?.textContent || '').trim()
			: '';
		const menu_position = parentMenuElement
			? parentMenuElement.classList.contains('js-first-level-menu-item')
				? 'Top Menu'
				: 'Other'
			: 'Other';

		GA4HeaderMenuTracker.trackMenuInteraction({
			event: 'custom_event',
			event_name: 'menu_navigation',
			menu_navigation: {
				menu_position: menu_position,
				menu_category: TrackingData.NAVIGATION_MENU,
				category_1: category_1,
				category_2: category_2,
			},
		});

		if (navigateUrl && navigateUrl.length) {
			Tracker.trackAndNavigate(
				{
					category: TrackingData.NAVIGATION_MENU,
					action: trackingAction,
					label: navigateUrl,
				},
				navigateUrl,
				openInNewWindow
			);
		}
	},

	calculatePosition(e) {
		const dropdown = jQuery(e.currentTarget).find('.js-dropdown-menu');
		const differenceWidth = dropdown.length
			? jQuery(window).width() - parseInt(dropdown.offset().left + dropdown.width(), 10)
			: 0;

		if (differenceWidth < 0) {
			dropdown.css('left', parseInt(dropdown.css('left'), 10) + differenceWidth);
		}
	},

	loadLoginForm(e, cb) {
		if (this.loadingLoginForm) {
			return;
		}

		if (this.html) {
			cb(e, this.html);

			return;
		}

		const data = { wishlist: false };
		const isSaveWishlist = e.currentTarget.classList.contains('js-save-wishlist');

		Xhr.getHtml(ApiEndpoints.GET_LOGIN_DIALOG, data)
			.then((html) => {
				// if there was click right after hover event to prevent have #login-modal in two places
				jQuery('#login-modal').parent().empty();

				this.html = html;

				cb(e, this.html);

				this.loadingIndicator.hideLoader();

				this.loadingLoginForm = false;
			})
			.catch((xhrResponse) => Xhr.logXhrPromiseRejection(xhrResponse, 'HeaderFeatures on loadLoginForm'));

		if (isSaveWishlist) {
			this.loadingIndicator.showLoader();
		}

		this.loadingLoginForm = true;
	},

	renderLoginPopup(e, html) {
		const isSaveWishlist = e.currentTarget.classList.contains('js-save-wishlist');

		jQuery('#login-modal').parent().empty();

		const popup = new Popup({ closeBtn: '.modal-window .close-btn, .modal-window .stay-on-page' }).init();

		this.attachEventsPopup(popup);

		popup.open(html);

		// hide title and description, switch to register tab
		if (!isSaveWishlist) {
			popup.content.find('#login-form .modal-title, #login-form .description').addClass('hide');

			popup.content.find('#login-form li[rel="tab-register"]').trigger('click');
		}
	},

	renderLoginFlyout(e, html) {
		const currentTarget = jQuery(e.currentTarget);
		const frame = currentTarget.find('.account-login .frame').length
			? currentTarget.find('.account-login .frame')
			: currentTarget.parents('#nav, #nav-fury').find('.login-flyout-popup .login-form');

		if (frame.find('#login-modal').length) {
			return;
		} // already rendered

		frame.html(html);

		frame.find('.back-button').attr('rel', 'login-form-flyout');

		frame.find('.modal-view').hide();

		frame.find('#login-form-flyout').show();

		frame
			.find('.register-me')
			.off()
			.on('click', (e) => {
				e.preventDefault();
				e.stopPropagation();

				jQuery('#login-modal').parents('#nav, #nav-fury').find('li.account>a').click();

				jQuery(this.config.overlay).click();
				this.renderLoginPopup(e, this.html);
			});

		frame
			.find('.forgot-password')
			.off()
			.on('click', (e) => {
				GA4UserEventsTracker.trackUserForgotPassword(this.config.trackingCategory);
			});

		frame
			.find('#login-modal .modal-view#login-form-flyout .submit-button')
			.off()
			.on('click', (e) => {
				GA4UserEventsTracker.trackUserLogin(this.config.trackingCategory);
			});

		const flyout = jQuery(e.currentTarget).find('.account-login');
		const flyoutInputs = flyout.find('input');

		flyoutInputs.on('focus', () => {
			flyout.addClass('active');
		});

		flyoutInputs.on('blur', () => {
			flyout.removeClass('active');
		});

		this.submitOnEnterPress(flyoutInputs);
	},

	// force submit on enter to be sure in cross-browser compatibility
	submitOnEnterPress(flyoutInputs) {
		flyoutInputs.on('keypress', (e) => {
			if (e.keyCode === 13) {
				e.preventDefault();

				jQuery(e.currentTarget).parents('form').find('input[type="submit"]').trigger('click');
				flyoutInputs.blur();
			}
		});
	},

	attachEventsPopup(popup) {
		popup.subscribe(
			'popup:closed',
			jQuery.proxy(function (e, isSuccess) {
				if (isSuccess) {
					jQuery(document).off('click', this.config.accountLink);
				}
			}, this)
		);
	},

	handleLinkToTrackClick(event, actionToTrack) {
		event.preventDefault();

		const navigateUrl = event.currentTarget.href;
		const openInNewWindow = !!event.currentTarget.target;

		const trackInfo = {
			category: TrackingData.PAGE_FURY_HEADER,
			action: actionToTrack,
			label: window.location.href,
		};

		if (navigateUrl && navigateUrl.length) {
			Tracker.trackAndNavigate(trackInfo, navigateUrl, openInNewWindow);
		} else {
			Tracker.track(trackInfo);
		}
	},

	// If we are on a newsletter success page we will receive
	// ?signed_up in the URL so we test this param to see
	// If we can track the current page as a newsletter success page
	trackNewsletterSuccessPage() {
		const urlParams = new URLSearchParams(location.search);
		if (urlParams.has('signed_up')) {
			GA4NewsletterTracker.trackNewsletterSuccess('Fury page');
		}
	},
});

HeaderFeatures.include(clickOut);

export default HeaderFeatures;
