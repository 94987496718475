"use strict";
/* tslint:disable no-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomEventTarget = void 0;
var CustomEventTarget = /** @class */ (function () {
    function CustomEventTarget() {
        this.listeners = [];
    }
    CustomEventTarget.prototype.addEventListener = function (type, callback) {
        if (!(type in this.listeners)) {
            this.listeners[type] = [];
        }
        this.listeners[type].push(callback);
    };
    CustomEventTarget.prototype.removeEventListener = function (type, callback) {
        if (!(type in this.listeners)) {
            return;
        }
        var stack = this.listeners[type];
        var l = stack.length;
        for (var i = 0; i < l; i++) {
            if (stack[i] === callback) {
                stack.splice(i, 1);
                return;
            }
        }
    };
    CustomEventTarget.prototype.dispatchEvent = function (event, data) {
        if (!(event.type in this.listeners)) {
            return true;
        }
        var stack = this.listeners[event.type].slice();
        var l = stack.length;
        for (var i = 0; i < l; i++) {
            stack[i].call(this, event, data);
        }
        return !event.defaultPrevented;
    };
    return CustomEventTarget;
}());
exports.CustomEventTarget = CustomEventTarget;
