"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4SocialNetworkTracker = void 0;
var GA4Tracker_1 = require("./GA4Tracker");
var GA4SocialNetworkTracker = /** @class */ (function () {
    function GA4SocialNetworkTracker() {
        this.attachClickHandlers();
    }
    GA4SocialNetworkTracker.prototype.attachClickHandlers = function () {
        var _this = this;
        var networkElements = Array.from(document.querySelectorAll('[data-network][data-position]'));
        networkElements.forEach(function (link) {
            link.addEventListener('click', function (e) {
                var target = e.currentTarget;
                var network = target.dataset.network || 'Unknown Network'; // Use default if undefined
                var position = target.dataset.position || 'Footer'; // Use default if undefined
                _this.trackSocialNetworkInteraction(network, position);
            });
        });
    };
    GA4SocialNetworkTracker.prototype.trackSocialNetworkInteraction = function (socialNetworkOption, ctaPosition) {
        var socialNetworkEvent = {
            event: 'custom_event',
            event_name: 'social_network',
            social_network_option: socialNetworkOption,
            cta_position: ctaPosition,
        };
        GA4Tracker_1.GA4Tracker.track(socialNetworkEvent);
    };
    return GA4SocialNetworkTracker;
}());
exports.GA4SocialNetworkTracker = GA4SocialNetworkTracker;
