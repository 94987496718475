import core from './core';

const IEFixes = core.extend({
	constructor(options) {
		this.config = {
			accountLink: '',
			loggedIn: false,
		};

		jQuery.extend(true, this.config, options || {});

		this.init();
	},

	init() {
		if (!this.config.loggedIn) {
			jQuery(this.config.accountLink).parent().find('div').remove();

			jQuery(document)
				.find(`.${jQuery(this.config.accountLink).attr('data-target')}`)
				.remove();

			this.detachEvents();
		}
	},

	detachEvents() {
		jQuery(this.config.accountLink).off('click');

		jQuery(this.config.accountLink).parent().off('mouseenter');
	},
});

export default IEFixes;
