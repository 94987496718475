"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnvironment = exports.EnvironmentNames = void 0;
var EnvironmentNames;
(function (EnvironmentNames) {
    EnvironmentNames["stage"] = "stage";
    EnvironmentNames["production"] = "production";
})(EnvironmentNames = exports.EnvironmentNames || (exports.EnvironmentNames = {}));
var Local = /** @class */ (function () {
    function Local() {
        this.isLocal = true;
        this.isProduction = false;
        this.isStage = false;
    }
    return Local;
}());
var Stage = /** @class */ (function () {
    function Stage() {
        this.isLocal = false;
        this.isProduction = false;
        this.isStage = true;
    }
    return Stage;
}());
var Production = /** @class */ (function () {
    function Production() {
        this.isLocal = false;
        this.isProduction = true;
        this.isStage = false;
    }
    return Production;
}());
function getEnvironment(location) {
    var isProduction = !!~location.host.indexOf('www');
    var isStage = !!~location.host.indexOf('stage');
    if (isProduction) {
        return new Production();
    }
    if (isStage) {
        return new Stage();
    }
    return new Local();
}
exports.getEnvironment = getEnvironment;
