const cookieWarning = `
<article id="js-cookie-warning" class="cookie-info">
	<div class="wrapper columns-wrapper cfx">
		<p class="text0">{{=text }}</p>
		<span class="close-btn js-close-btn">
			<span class="close-btn-text">{{=close}}</span>
			<span class="with-icon svg-icon-close-grey"></span>
		</span>
	</div>
</article>`;

export default cookieWarning;
