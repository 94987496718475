"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4Tracker = void 0;
var dom_interact_1 = require("../../helpers/dom-interact");
var GA4Tracker = /** @class */ (function () {
    function GA4Tracker() {
    }
    GA4Tracker.isTrackingEnabled = function () {
        return typeof dataLayer !== 'undefined';
    };
    GA4Tracker.track = function (options, config) {
        if (config === void 0) { config = {}; }
        if (!GA4Tracker.isTrackingEnabled()) {
            return;
        }
        if (config.delay) {
            setTimeout(function () {
                GA4Tracker._track(options);
            }, config.delay);
        }
        else {
            GA4Tracker._track(options);
        }
    };
    GA4Tracker._track = function (options) {
        if (!GA4Tracker.isTrackingEnabled()) {
            return;
        }
        if (options.clearEcommerce) {
            dataLayer.push({ ecommerce: null });
        }
        dataLayer.push(options);
    };
    GA4Tracker.trackAndNavigate = function (options, url, openInNewWindow) {
        if (openInNewWindow === void 0) { openInNewWindow = false; }
        if (!GA4Tracker.isTrackingEnabled()) {
            dom_interact_1.navigate(url, openInNewWindow);
            return;
        }
        try {
            var navigateTimeout_1 = setTimeout(function () { return dom_interact_1.navigate(url, openInNewWindow); }, 1000);
            var trackingOptions = __assign(__assign({}, options), { eventCallback: function () {
                    clearTimeout(navigateTimeout_1);
                    dom_interact_1.navigate(url, openInNewWindow);
                } });
            if (options.clearEcommerce) {
                dataLayer.push({ ecommerce: null });
            }
            dataLayer.push(trackingOptions);
        }
        catch (error) {
            dom_interact_1.navigate(url, openInNewWindow);
        }
    };
    return GA4Tracker;
}());
exports.GA4Tracker = GA4Tracker;
