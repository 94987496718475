import HeaderFeatures from '../components/header-features';
import Common from '../components/common';
import UpdateUserAccount from '../components/update-user-account';
import PageTypes from '../helpers/page-types';
import { Scroller } from '../components/galleries/scroller';
import { TrackingData } from '../helpers/TrackingTypes';
import { GA4SocialNetworkTracker } from '../components/GA4/GA4SocialNetworkTracker';
import { GA4ErrorTracker } from '../components/GA4/GA4ErrorTracker';
import { GA4EcommerceTracker } from '../components/GA4/GA4EcommerceTracker';
import { initBanners } from '../components/banners/index';
import { EnhanceEcommerce } from '../helpers/EnhanceEcommerce';
import { SentryLogger } from '../helpers/Sentry';

function instantiateScrollers() {
	const scrollers = document.querySelectorAll('.js-products-scroller');

	for (let i = 0; i < scrollers.length; i++) {
		new Scroller(scrollers[i], {
			classes: {
				viewport: '.js-viewport',
				slide: '.js-slide',
				prevButton: '.js-prev-button',
				nextButton: '.js-next-button',
			},
			buttonState: 'is-disabled',
		});
	}
}

function trackBeginCheckout() {
	EnhanceEcommerce.pullBasketItems()
		.then((items) => {
			GA4EcommerceTracker.trackBeginCheckout(items);
		})
		.catch((error) => {
			SentryLogger.getInstance().sendInfoReport({
				message: `GA4("begin checkout") catch exception "${error.message}"`,
			});
		});
}

function trackAddShippingInfo() {
	const selectedShippingMethod = document.querySelector('#checkout-shipping-method-load input[type="radio"]:checked');
	if (selectedShippingMethod) {
		const shippingLabel = selectedShippingMethod.closest('.radio-row').querySelector('label.radio');
		const shippingTier = shippingLabel ? shippingLabel.textContent.trim() : 'unknown';

		EnhanceEcommerce.pullBasketItems()
			.then((items) => {
				GA4EcommerceTracker.trackAddShippingInfo(items, shippingTier);
			})
			.catch((error) => {
				SentryLogger.getInstance().sendInfoReport({
					message: `GA4("track shipping") catch exception "${error.message}"`,
				});
			});
	}
}

function trackAddPaymentInfo() {
	const selectedPaymentMethod = document.querySelector('#checkout-payment-method-load input[type="radio"]:checked');
	if (selectedPaymentMethod) {
		const paymentLabel = selectedPaymentMethod.closest('.radio-row').querySelector('label.radio');
		const paymentType = paymentLabel ? paymentLabel.textContent.trim() : 'unknown';
		EnhanceEcommerce.pullBasketItems()
			.then((items) => {
				GA4EcommerceTracker.trackAddPaymentInfo(items, paymentType);
			})
			.catch((error) => {
				SentryLogger.getInstance().sendInfoReport({
					message: `GA4("track payment") catch exception "${error.message}"`,
				});
			});
	}
}

// Add and remove event listeners explicitly
function addCheckoutEventListeners() {
	const shippingButton = document.querySelector('#shipping-method-buttons-container .default-btn');
	if (shippingButton) {
		shippingButton.removeEventListener('click', trackAddShippingInfo);
		shippingButton.addEventListener('click', trackAddShippingInfo);
	}

	const paymentButton = document.querySelector('#checkout-step-payment .default-btn');
	if (paymentButton) {
		paymentButton.removeEventListener('click', trackAddPaymentInfo);
		paymentButton.addEventListener('click', trackAddPaymentInfo);
	}
}

function observeCheckoutStepLoading() {
	const checkoutStepReview = document.querySelector('#checkout-step-review');
	if (!checkoutStepReview) return;

	const observer = new MutationObserver(() => {
		addCheckoutEventListeners();
	});

	observer.observe(checkoutStepReview, { childList: true, subtree: true });
}

function isPurchaseDataLayer(data) {
	return (
		typeof data === 'object' &&
		data !== null &&
		'siteStructure' in data &&
		data.siteStructure === '::::::Checkout Success' &&
		'ecommerce' in data &&
		'purchase' in data.ecommerce
	);
}

function trackGa4PurchaseEvent() {
	if (typeof dataLayer !== 'undefined' && dataLayer) {
		var ga4RawPurchaseEventData = dataLayer.find((element) => isPurchaseDataLayer(element));

		if (ga4RawPurchaseEventData) {
			var isSampleOrder = false;
			var isRegularOrder = false;
			var items = [];
			ga4RawPurchaseEventData.ecommerce.purchase.products.forEach((product) => {
				if ('dimension6' in product) {
					isSampleOrder = isSampleOrder || product.dimension6 === 'true';
					isRegularOrder = isRegularOrder || product.dimension6 !== 'true';
				}

				const item = EnhanceEcommerce.buildGA4ItemFromCommonTrackingData(
					product,
					parseFloat(product.price),
					parseInt(product.quantity),
					items.length + 1,
					'Order',
					'order'
				);
				items.push(item);
			});

			if (items.length > 0) {
				const total =
					'actionField' in ga4RawPurchaseEventData.ecommerce.purchase &&
					'revenue' in ga4RawPurchaseEventData.ecommerce.purchase.actionField
						? parseFloat(ga4RawPurchaseEventData.ecommerce.purchase.actionField.revenue)
						: null;

				GA4EcommerceTracker.trackPurchase(
					ga4RawPurchaseEventData.transactionId || '',
					total,
					parseFloat(ga4RawPurchaseEventData.transactionTax),
					parseFloat(ga4RawPurchaseEventData.transactionShipping),
					items,
					isSampleOrder,
					isRegularOrder
				);
			}
		}
	}
}

const FuryInMagento = function () {
	new Common({
		trackingCategory: TrackingData.PAGE_FURY_IN_MAGENTO,
	}); // Common should be first because it contains error-logger

	initBanners('HomePage');

	new HeaderFeatures({
		trackingCategory: TrackingData.PAGE_FURY_IN_MAGENTO,
	});
	new GA4SocialNetworkTracker();

	const errorPage = document.querySelectorAll('[data-page-error]');

	if (errorPage.length) {
		new GA4ErrorTracker.track404Error();
	}

	const header = document.getElementById('header');

	if (header) {
		new UpdateUserAccount(header, PageTypes.MAGENTO);
	}

	instantiateScrollers();

	trackGa4PurchaseEvent();

	observeCheckoutStepLoading();

	const checkoutForm = document.querySelector('#check-checkout-form');
	if (checkoutForm) {
		trackBeginCheckout();
	}
};

new FuryInMagento();
