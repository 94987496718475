"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4BannerTracker = void 0;
var GA4Tracker_1 = require("./GA4Tracker");
var GA4BannerTracker = /** @class */ (function () {
    function GA4BannerTracker() {
    }
    GA4BannerTracker.trackBannerClick = function (bannerData) {
        var _a;
        var event_name = typeof categoryName !== 'undefined' && categoryName === 'home'
            ? 'home_content_interaction'
            : 'category_content_interaction';
        var bannerClickEvent = (_a = {
                event: 'custom_event',
                event_name: event_name
            },
            _a[event_name] = bannerData,
            _a);
        GA4Tracker_1.GA4Tracker.track(bannerClickEvent);
    };
    return GA4BannerTracker;
}());
exports.GA4BannerTracker = GA4BannerTracker;
