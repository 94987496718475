"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WishListStore = void 0;
var HttpRequest_1 = require("../../Modules/HttpRequest");
var ApiEndpoints_1 = require("../../Configurations/ApiEndpoints");
var CustomEventTarget_1 = require("../../helpers/CustomEventTarget");
var Sentry_1 = require("../../helpers/Sentry");
var Types_1 = require("../../Modules/Types");
var WishListStore = /** @class */ (function (_super) {
    __extends(WishListStore, _super);
    function WishListStore() {
        var _this = _super.call(this) || this;
        _this.itemsList = [];
        setTimeout(function () {
            _this.loadWishListItems();
        }, 0);
        window.addEventListener('pageshow', function (evt) {
            if (evt.persisted) {
                _this.loadWishListItems(true);
            }
        }, false);
        return _this;
    }
    WishListStore.prototype.loadWishListItems = function (isBFCache) {
        var _this = this;
        HttpRequest_1.Xhr.getJson(ApiEndpoints_1.ApiEndpoints.GET_WISHLIST_JSON)
            .then(function (response) {
            _this.addWishListItems(response, isBFCache);
        })
            .catch(function (xhrResponse) { return HttpRequest_1.Xhr.logXhrPromiseRejection(xhrResponse, 'WishListStore on loadWishListItems'); });
    };
    WishListStore.prototype.addWishListItems = function (wishListItems, isBFCache) {
        this.itemsList = isBFCache ? __spreadArray([], wishListItems) : __spreadArray(__spreadArray([], this.itemsList), wishListItems);
        this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_ITEMS_LOADED), __spreadArray([], this.itemsList));
    };
    WishListStore.prototype.deleteWishListItemsFromStore = function (deleteIdList) {
        this.itemsList = this.itemsList.filter(function (item) { return !deleteIdList.includes(item.wishlistItemId); });
        this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_ITEMS_LOADED), __spreadArray([], this.itemsList));
    };
    WishListStore.prototype.getAmountOfWishListItems = function () {
        return this.itemsList.length;
    };
    WishListStore.prototype.isItemInWishList = function (state) {
        var _this = this;
        return this.itemsList.some(function (item) { return _this.isItemsMatch(state, item); });
    };
    WishListStore.prototype.isItemsMatch = function (wishListButtonState, itemInWishList) {
        return Object.keys(wishListButtonState)
            .filter(function (stateOption) { return wishListButtonState[stateOption]; })
            .every(function (stateOption) { return wishListButtonState[stateOption] === itemInWishList.options[stateOption]; });
    };
    WishListStore.prototype.getAllItemMatches = function (options) {
        var _this = this;
        return this.itemsList.filter(function (item) { return _this.isItemsMatch(options, item); });
    };
    WishListStore.prototype.getItemsIds = function (itemsList) {
        return itemsList.map(function (item) { return item.wishlistItemId; });
    };
    WishListStore.prototype.isItemConfigured = function (itemsList) {
        return !!itemsList.filter(function (item) { return item.isConfigured; }).length;
    };
    WishListStore.prototype.addToWishListRequest = function (wishListButtonState) {
        var _this = this;
        HttpRequest_1.Xhr.postFormData(ApiEndpoints_1.ApiEndpoints.ADD_TO_WISHLIST, wishListButtonState)
            .then(function (response) { return _this.parseAddItemResponse(response); })
            .catch(function (xhrResponse) {
            _this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_ADD_ERROR));
            HttpRequest_1.Xhr.logXhrPromiseRejection(xhrResponse, 'WishListStore on addToWishListRequest');
        });
    };
    WishListStore.prototype.parseAddItemResponse = function (response) {
        if (!response || (response.error && !response.message)) {
            this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_ADD_ERROR));
            Sentry_1.SentryLogger.getInstance().sendInfoReport({
                message: 'Ajax/network error during add to wishList process',
            });
        }
        if (response.code === 'ok') {
            this.addWishListItems([response.data]);
            this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_ADD_SUCCESS));
        }
        else if (response.error && response.message) {
            this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_ADD_ERROR));
            Sentry_1.SentryLogger.getInstance().sendInfoReport({
                message: "Error during add to wishList process " + response.message,
            });
        }
    };
    WishListStore.prototype.deleteFromWishListRequest = function (idList, isItemConfigured) {
        var _this = this;
        if (isItemConfigured === void 0) { isItemConfigured = false; }
        HttpRequest_1.Xhr.postFormData(ApiEndpoints_1.ApiEndpoints.REMOVE_FROM_WISHLIST, {
            item: idList,
        })
            .then(function (response) { return _this.parseDeleteItemResponse(response, idList, isItemConfigured); })
            .catch(function (xhrResponse) {
            _this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_DELETE_ERROR));
            HttpRequest_1.Xhr.logXhrPromiseRejection(xhrResponse, 'WishListStore on deleteFromWishListRequest');
        });
    };
    WishListStore.prototype.parseDeleteItemResponse = function (response, idList, isItemConfigured) {
        if (!response || (response.error && !response.message)) {
            this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_DELETE_ERROR));
            Sentry_1.SentryLogger.getInstance().sendInfoReport({
                message: 'Ajax/network error during delete from wishList process',
            });
        }
        if (response.code === 'ok') {
            this.deleteWishListItemsFromStore(idList);
            this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_DELETE_SUCCESS), isItemConfigured);
        }
        else if (response.error && response.message) {
            this.dispatchEvent(new CustomEvent(Types_1.WishListEvents.STORE_DELETE_ERROR));
            Sentry_1.SentryLogger.getInstance().sendInfoReport({
                message: "Error during delete from wishList process " + response.message,
            });
        }
    };
    return WishListStore;
}(CustomEventTarget_1.CustomEventTarget));
exports.WishListStore = WishListStore;
