"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WishListEvents = exports.WishListTemplateIds = void 0;
var WishListTemplateIds;
(function (WishListTemplateIds) {
    WishListTemplateIds["ADD_TO_WISHLIST_SUCCESS"] = "#wishlist-message-add-to-wishlist";
    WishListTemplateIds["ADD_TO_WISHLIST_ERROR"] = "#wishlist-message-add-to-wishlist-error";
    WishListTemplateIds["DELETE_ONE_ITEM_IS_CONFIGURED"] = "#wishlist-message-one-item-is-configured";
    WishListTemplateIds["DELETE_ONE_OF_MANY_ITEM_IS_CONFIGURED"] = "#wishlist-message-one-of-many-item-is-configured";
    WishListTemplateIds["DELETE_SUCCESS"] = "#wishlist-message-delete-success";
    WishListTemplateIds["DELETE_ERROR"] = "#wishlist-message-delete-error";
})(WishListTemplateIds = exports.WishListTemplateIds || (exports.WishListTemplateIds = {}));
var WishListEvents;
(function (WishListEvents) {
    WishListEvents["STORE_ITEMS_LOADED"] = "store:items:loaded";
    WishListEvents["STORE_ADD_ERROR"] = "store:add:error";
    WishListEvents["STORE_ADD_SUCCESS"] = "store:add:success";
    WishListEvents["STORE_DELETE_ERROR"] = "store:delete:error";
    WishListEvents["STORE_DELETE_SUCCESS"] = "store:delete:success";
    WishListEvents["BUTTON_CLICK"] = "button:click";
    WishListEvents["BUTTON_STATE_CHANGE"] = "button:state:change";
    WishListEvents["REQUEST_START"] = "wishList:request:start";
    WishListEvents["REQUEST_END"] = "wishList:request:end";
    WishListEvents["POPUP_OPEN"] = "wishList:popup:open";
    WishListEvents["POPUP_CLOSE"] = "wishList:popup:close";
    WishListEvents["POPUP_DISABLE"] = "wishList:popup:disable";
})(WishListEvents = exports.WishListEvents || (exports.WishListEvents = {}));
